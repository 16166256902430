import { format } from "date-fns";

import { AddChangeReadRequestForm } from "../../components";
import { ChangeReadRequest, IChangeReadFormValues } from "../../models/talos";
import { addChangeReadRequest } from "../../api/talos";
import { AdditionPage } from "../addition-page";

const initialValues: IChangeReadFormValues = {
	mpan: "",
	msn: "",
	readDate: undefined,
	correctReadType: undefined,
	firstRegisterId: "",
	firstRegisterIncorrectRead: 0,
	firstRegisterCorrectRead: 0,
	subRegisters: "N",
	secondRegisterId: undefined,
	secondRegisterIncorrectRead: 0,
	secondRegisterCorrectRead: 0,
};

const mapFormToValues = (values: IChangeReadFormValues): ChangeReadRequest => {
	const minimumRequest = {
		mpan: Number(values.mpan),
		msn: values.msn,
		readDate:
			values.readDate !== undefined
				? format(values.readDate, "yyyy-MM-dd")
				: "",
		correctReadType: values.correctReadType!,
		reg1Data: {
			regId: values.firstRegisterId,
			regIncorrectRead: values.firstRegisterIncorrectRead ?? 0,
			regCorrectRead: values.firstRegisterCorrectRead ?? 0,
		},
	};

	return values.subRegisters === "Y" &&
		values.secondRegisterId != undefined &&
		values.secondRegisterIncorrectRead != undefined &&
		values.secondRegisterCorrectRead != undefined
		? {
				...minimumRequest,
				reg2Data: {
					regId: values.secondRegisterId,
					regIncorrectRead: values.secondRegisterIncorrectRead ?? 0,
					regCorrectRead: values.secondRegisterCorrectRead ?? 0,
				},
		  }
		: minimumRequest;
};

export const AddChangeReadRequest: React.FC = () => {
	return (
		<>
			<AdditionPage<ChangeReadRequest, IChangeReadFormValues>
				additionFormKey="add-change-read-request"
				additionInstanceName="Change Read"
				header="New Change Read Request"
				summary="Fill in this form to send a new Change Read Request."
				mapFormToValues={mapFormToValues}
				addInstanceRequest={addChangeReadRequest}
				AddInstanceForm={AddChangeReadRequestForm}
				initialValues={initialValues}
				listPage={{
					subject: "change read requests",
					title: "Change Read Requests",
					link: "/change-read-requests",
				}}
			/>
		</>
	);
};
