import { PagedRequestsPage } from "../requests-list-page";
import { getSI2Flows } from "../../api/talos";
import { flowsTable } from "../../components/tables/flows-table";
import { formatJavaStyleDateString } from "../../utilities";
import { SI2FlowsResponse } from "../../models/talos/si2-flows";

const responseKey = (key: string): key is keyof SI2FlowsResponse =>
	[
		"id",
		"flowId",
		"userId",
		"mpan",
		"status",
		"cause",
		"flowType",
		"createdAt",
		"updatedAt",
	].includes(key);

const responseDataKey = (key: string): key is keyof SI2FlowsResponse["data"] =>
	[
		"addressLine1",
		"addressLine2",
		"addressLine3",
		"addressLine4",
		"addressLine5",
		"addressLine6",
		"postCode",
		"additionalInformation",
	].includes(key);

const MeteringPointAddressAmendmentTable = flowsTable<SI2FlowsResponse>({
	columns: [
		{ label: "ID", key: "id" },
		{ label: "MPAN", key: "mpan" },
		{ label: "Organisation", key: "addressLine1" },
		{ label: "Flat Number", key: "addressLine2" },
		{ label: "Building Number", key: "addressLine3" },
		{ label: "Street Name", key: "addressLine4" },
		{ label: "Town", key: "addressLine6" },
		{ label: "Post Code", key: "postCode" },
		{ label: "Additional Information", key: "additionalInformation" },
		{
			label: "Created",
			key: "createdAt",
			formatter: formatJavaStyleDateString,
		},
		{
			label: "Updated",
			key: "updatedAt",
			formatter: formatJavaStyleDateString,
		},
	],
	dataKey: "si2",
	getId: (response: SI2FlowsResponse) => response.id,
	getColumnContent: (columnKey, response) => {
		// Look up value based on where in the response type the column key exists.
		if (responseKey(columnKey)) return response[columnKey];

		if (responseDataKey(columnKey)) return response.data[columnKey];

		throw Error("Unknown column key");
	},
});

export const SI2Flows = () => (
	<PagedRequestsPage<SI2FlowsResponse>
		title="Meter Point Address Update"
		requestsName={"Meter Point Address Update"}
		requestsKey={"si2-flows"}
		getRequests={getSI2Flows}
		RequestsTable={MeteringPointAddressAmendmentTable}
		additionLink={"/update-meter-point-address"}
		getRequestsOptions={{}}
	/>
);
