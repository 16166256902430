import React from "react";

import { useFormikContext } from "formik";
import { format } from "date-fns";

import {
	API_TYPES,
	getFVRRequests,
	SETTLEMENTS_READS_TYPES,
} from "../../api/talos";
import { generateFormPage, selectOptionsFromEnum } from "../../form-generator";
import { COMMON_FIELDS } from "../../form-generator/form-component.generator";
import {
	AddFVRequest,
	FVRFormValues,
	FVRReadType,
	FVRResponse,
} from "../../models/talos/force-validation-reads";
import { useRecentSettlementsAlert } from "../../utilities/settlements-reads.helpers";
import {
	NEWER_THAN_292_WORKING_DAYS_MSG,
	PAST,
	XWorkdaysFromDate,
} from "../../utilities";

const mapFormToValues = ({
	mpan,
	msn,
	readDate,
	readType,
	reg1Id,
	reg1Read,
	reg2Id,
	reg2Read,
	hasSecondRegister,
}: FVRFormValues): AddFVRequest => {
	const minimumRequest = {
		mpan: Number(mpan),
		msn: msn,
		readType,
		readDate: format(readDate, "yyyy-MM-dd"),
		reg1Data: { regId: reg1Id, read: reg1Read },
	};

	return hasSecondRegister === "yes" &&
		reg2Id != undefined &&
		reg2Read != undefined
		? {
				...minimumRequest,
				reg2Data: { regId: reg2Id, read: reg2Read },
		  }
		: minimumRequest;
};

const minimumReadDate = () => XWorkdaysFromDate(new Date(), 292, PAST);

export const AddFVRRequest = generateFormPage<AddFVRequest, FVRFormValues>(
	{
		formKey: "add-fvr-request",
		title: "Force Validate Read",
		mapFormToValues,
		fields: [
			{
				componentType: "section",
				fields: [
					COMMON_FIELDS.MPAN,
					{
						...COMMON_FIELDS.MSN,
						required: true,
					},
					{
						label: "Read Date",
						fieldName: "readDate",
						componentType: "date",
						required: true,
						// Read date must be in the past 14 months.
						datePickerProps: {
							disableFuture: true,
							minDate: minimumReadDate(),
						},
						customValidation: (schema) =>
							schema
								.min(minimumReadDate(), NEWER_THAN_292_WORKING_DAYS_MSG)
								.max(new Date(), NEWER_THAN_292_WORKING_DAYS_MSG),
					},
					{
						label: "Read Type",
						fieldName: "readType",
						componentType: "select",
						required: true,
						options: selectOptionsFromEnum(FVRReadType, "key - value", "key"),
					},
					{
						label: "Register 1 ID",
						fieldName: "reg1Id",
						componentType: "string",
						required: true,
						minLength: 1,
						maxLength: 2,
					},
					{
						...COMMON_FIELDS.REGISTER_READ,
						label: "Register 1 Read",
						fieldName: "reg1Read",
						required: true,
					},
					{
						label: "Second register?",
						fieldName: "hasSecondRegister",
						componentType: "radio",
						required: true,
						defaultValue: "no",
						options: [
							{ label: "Yes", value: "yes" },
							{ label: "No", value: "no" },
						],
					},
				],
			},
			{
				componentType: "section",
				title: "Register 2",
				excludeFromForm: (values) => values["hasSecondRegister"] != "yes",
				fields: [
					{
						label: "Register 2 ID",
						fieldName: "reg2Id",
						componentType: "string",
						required: true,
						minLength: 1,
						maxLength: 2,
					},
					{
						...COMMON_FIELDS.REGISTER_READ,
						label: "Register 2 Read",
						fieldName: "reg2Read",
						required: true,
					},
				],
			},
		],
		listPage: {
			subject: "force validate read requests",
			title: "Force Validate Read Requests",
			link: "/force-validate-read-requests",
		},
		FormWrapper: ({ children }: { children: React.ReactNode }) => {
			const { values } = useFormikContext<AddFVRequest>();
			const mpan = values.mpan.toString();

			useRecentSettlementsAlert<FVRResponse>(
				mpan,
				SETTLEMENTS_READS_TYPES.FVR,
				getFVRRequests
			);

			return <>{children}</>;
		},
	},
	{ apiType: API_TYPES.SETTLEMENTS_READS, path: "fvr" }
);
