import * as Yup from "yup";

import { dateIsNotValid, usefulRegex } from "../../utilities";

function isLessThanOneYear(date: Date | string): boolean {
	const oneYearAgo = new Date();
	const givenDate = new Date(date);

	oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
	oneYearAgo.setHours(0, 0, 0, 0);

	givenDate.setHours(0, 0, 0, 0);

	return givenDate >= oneYearAgo;
}

export const addD0205FlowFormValidationSchema = Yup.object().shape({
	mpan: Yup.string()
		.matches(usefulRegex.MPAN, "MPAN must be a 13 digit integer")
		.required("MPAN is required"),
	effectiveFromDateMSES: Yup.string().when("energisationStatus", {
		is: (value: string) => value !== undefined,
		then: (schema) =>
			schema
				.required("Energ. Status Effective From Date is required")
				.test(
					"invalid-date-action",
					"Energ. Status Effective From Date should be a valid date",
					dateIsNotValid
				)
				.test(
					"date-in-past-year",
					"Energ. Status Effective From Date cannot be more than a year in the past",
					isLessThanOneYear
				),
		otherwise: (schema) => schema.notRequired(),
	}),
	effectiveFromDateMSMC: Yup.string().when("measurementClassId", {
		is: (value: string) => value !== undefined,
		then: (schema) =>
			schema
				.required("Measurement Class Effective From Date is required")
				.test(
					"invalid-date-action",
					"Measurement Class Effective From Date should be a valid date",
					dateIsNotValid
				)
				.test(
					"date-in-past-year",
					"Measurement Class Effective From Date cannot be more than a year in the past",
					isLessThanOneYear
				),
		otherwise: (schema) => schema.notRequired(),
	}),
	meterTimeswitchCode: Yup.string()
		.optional()
		.matches(
			/^[0-9]{1,3}$/,
			"Meter Timeswitch Code must contain only digits with a maximum length of 3"
		),
	meterTimeswitchCodeEffectiveFromDate: Yup.string().when(
		"meterTimeswitchCode",
		{
			is: (value: string) => value !== undefined,
			then: (schema) =>
				schema
					.required("Meter Timeswitch Code Effective From Date is required")
					.test(
						"invalid-date-action",
						"Meter Timeswitch Code Effective From Date should be a valid date",
						dateIsNotValid
					)
					.test(
						"date-in-past-year",
						"Meter Timeswitch Code Effective From Date cannot be more than a year in the past",
						isLessThanOneYear
					),
			otherwise: (schema) => schema.notRequired(),
		}
	),
	effectiveFromDateMSPC: Yup.string().when("pcId", {
		is: (value: string) => value !== undefined,
		then: (schema) =>
			schema
				.required("Profile Class Effective From Date is required")
				.test(
					"invalid-date-action",
					"Profile Class Effective From Date should be a valid date",
					dateIsNotValid
				)
				.test(
					"date-in-past-year",
					"Profile Class Effective From Date cannot be more than a year in the past",
					isLessThanOneYear
				),
		otherwise: (schema) => schema.notRequired(),
	}),
	sscId: Yup.string()
		.optional()
		.matches(
			/^[0-9]{4}$/,
			"Standard Settlement Configuration Id must be a 4 digit number"
		),
	effectiveFromDateSCON: Yup.string().when("sscId", {
		is: (value: string) => value !== undefined,
		then: (schema) =>
			schema
				.required(
					"Standard Settlement Configuration Effective From Date is required"
				)
				.test(
					"invalid-date-action",
					"Standard Settlement Configuration Effective From Date should be a valid date",
					dateIsNotValid
				)
				.test(
					"date-in-past-year",
					"Standard Settlement Configuration Effective From Date cannot be more than a year in the past",
					isLessThanOneYear
				),
		otherwise: (schema) => schema.notRequired(),
	}),
	dataAggregatorId: Yup.string()
		.optional()
		.max(4, "Data Aggregator Id must be between 1 and 4 character long"),
	dataAggregationType: Yup.string().when("dataAggregatorId", {
		is: (value: string) => value !== undefined,
		then: (schema) => schema.required("Data Aggregation Type is required"),
	}),
	effectiveFromDateDAA: Yup.string().when("dataAggregatorId", {
		is: (value: string) => value !== undefined,
		then: (schema) =>
			schema
				.required("Data Aggregator Effective From Date is required")
				.test(
					"invalid-date-action",
					"Data Aggregator Effective From Date should be a valid date",
					dateIsNotValid
				)
				.test(
					"date-in-past-year",
					"Data Aggregator Effective From Date cannot be more than a year in the past",
					isLessThanOneYear
				),
		otherwise: (schema) => schema.notRequired(),
	}),
	dataCollectorId: Yup.string()
		.optional()
		.max(4, "Data Collector Id must be between 1 and 4 character long"),
	dataCollectorType: Yup.string().when("dataCollectorId", {
		is: (value: string) => value !== undefined,
		then: (schema) => schema.required("Data Collector Type is required"),
	}),
	effectiveFromDateDCA: Yup.string().when("dataCollectorId", {
		is: (value: string) => value !== undefined,
		then: (schema) =>
			schema
				.required("Data Collector Effective From Date is required")
				.test(
					"invalid-date-action",
					"Data Collector Effective From Date should be a valid date",
					dateIsNotValid
				)
				.test(
					"date-in-past-year",
					"Data Collector Effective From Date cannot be more than a year in the past",
					isLessThanOneYear
				),
		otherwise: (schema) => schema.notRequired(),
	}),
	meterOperatorId: Yup.string()
		.optional()
		.max(4, "Meter Operator Id must be between 1 and 4 character long"),
	meterOperatorType: Yup.string().when("meterOperatorId", {
		is: (value: string) => value !== undefined,
		then: (schema) => schema.required("Meter Operator Type is required"),
	}),
	effectiveFromDateMOA: Yup.string().when("meterOperatorId", {
		is: (value: string) => value !== undefined,
		then: (schema) =>
			schema
				.required("MOP Effective From Date is required")
				.test(
					"invalid-date-action",
					"MOP Effective From Date should be a valid date",
					dateIsNotValid
				)
				.test(
					"date-in-past-year",
					"MOP Effective From Date cannot be more than a year in the past",
					isLessThanOneYear
				),
		otherwise: (schema) => schema.notRequired(),
	}),
	effectiveFromDateIHDI: Yup.string().when("ihdStatus", {
		is: (value: string) => value !== undefined,
		then: (schema) =>
			schema
				.required("IHD Status Effective From Date is required")
				.test(
					"invalid-date-action",
					"IHD Status Effective From Date should be a valid date",
					dateIsNotValid
				)
				.test(
					"date-in-past-year",
					"IHD Status Effective From Date cannot be more than a year in the past",
					isLessThanOneYear
				),
		otherwise: (schema) => schema.notRequired(),
	}),
	smartMeterOperatorId: Yup.string()
		.optional()
		.max(4, "Smart Meter Operator Id must be between 1 and 4 character long"),
	effectiveFromDateSMSO: Yup.string().when("smartMeterOperatorId", {
		is: (value: string) => value !== undefined,
		then: (schema) =>
			schema
				.required("Smart Meter Operator Effective From Date is required")
				.test(
					"invalid-date-action",
					"Smart Meter Operator Effective From Date should be a valid date",
					dateIsNotValid
				)
				.test(
					"date-in-past-year",
					"Smart Meter Operator Effective From Date cannot be more than a year in the past",
					isLessThanOneYear
				),
		otherwise: (schema) => schema.notRequired(),
	}),
});
