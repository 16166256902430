export enum EnergisationStatus {
	"De-Energised" = "D",
	"Energised" = "E",
}

export enum MeterLocation {
	A = "A",
	B = "B",
	C = "C",
	D = "D",
	E = "E",
	F = "F",
	G = "G",
	H = "H",
	I = "I",
	J = "J",
	K = "K",
	L = "L",
	M = "M",
	N = "N",
	O = "O",
	P = "P",
	R = "R",
	S = "S",
	T = "T",
	U = "U",
	V = "V",
	W = "W",
	X = "X",
	Y = "Y",
	Z = "Z",
}

export enum ReadingType {
	"Non Half-Hourly" = "N",
	"Half-Hourly" = "H",
}

export enum IHDStatus {
	"INSTALLED" = "I",
	"DECLINED" = "D",
	"EXISTING" = "E",
	"FAILED" = "F",
}

export enum MeasurementClassId {
	"Non Half Hourly Metered" = "A",
	"Non Half Hourly Unmetered" = "B",
	"HH metered in 100kW Premises" = "C",
	"Half Hourly Unmetered" = "D",
	"HH metered sub 100kW CT" = "E",
	"HH metered sub 100kW domestic" = "F",
	"HH metered sub 100kW nondom wc" = "G",
}

export enum SMETSVersion {
	"SMETS Meter No Longer Installed" = "NONE",
	"SMETS Version 1" = "SMETS1",
	"SMETS Version 2" = "SMETS2",
}
