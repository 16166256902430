import React from "react";

import { format } from "date-fns";

import { addMW4FlowRequest } from "../../api/talos";
import { IFlowFormValues, MW4FlowRequest } from "../../models/talos/mw4-flows";
import { AddMW4FlowForm } from "../../components/mw4";
import { AdditionPage } from "../addition-page";

const initialValues: IFlowFormValues = {
	mpan: "",
	measurementQuantityId: "",
	consumptionAmendmentSupplierReference: "",
	consumptionAmendmentPeriodStartDate: "",
	consumptionAmendmentPeriodEndDate: "",
	grossRevisedConsumption: "",
	supplierConsumptionAmendmentReasonCode: "",
};

const mapFormToValues = (values: IFlowFormValues): MW4FlowRequest => {
	return {
		...values,
		consumptionAmendmentPeriodStartDate:
			values.consumptionAmendmentPeriodStartDate
				? format(
						new Date(values.consumptionAmendmentPeriodStartDate),
						"yyyy-MM-dd"
				  )
				: "",
		consumptionAmendmentPeriodEndDate: values.consumptionAmendmentPeriodEndDate
			? format(new Date(values.consumptionAmendmentPeriodEndDate), "yyyy-MM-dd")
			: "",
		consumptionAmendmentSupplierReference:
			values.consumptionAmendmentSupplierReference === ""
				? null
				: values.consumptionAmendmentSupplierReference,
	};
};

export const AddMW4Flow = () => (
	<AdditionPage<MW4FlowRequest, IFlowFormValues>
		additionFormKey="add-mw4-flow"
		additionInstanceName="Consumption Amendment"
		underlyingFlowName="S0003_MW4"
		mapFormToValues={mapFormToValues}
		addInstanceRequest={addMW4FlowRequest}
		AddInstanceForm={AddMW4FlowForm}
		initialValues={initialValues}
		listPage={{
			link: "/consumption-amendment-requests",
			subject: "consumption amendments",
			title: "Consumption Amendments",
		}}
	/>
);
