// https://en.wikipedia.org/wiki/Postcodes_in_the_United_Kingdom
// NOTE: not including special cases https://en.wikipedia.org/wiki/Postcodes_in_the_United_Kingdom#Special_cases
export const postCodes = {
	POST_OUTCODE_REGEX: new RegExp("^[a-zA-Z]{1,2}[0-9][a-zA-Z0-9]?$"),
	POST_INCODE_REGEX: new RegExp("^[0-9][a-zA-Z]{2}$"),
	/**
	 * UK Postal Code
	 */
	UK_POST_CODE: new RegExp(
		"^(([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\\s?[0-9][A-Za-z]{2}))$"
	),
};

export const usefulRegex = {
	AT_LEAST_ONE_LETTER: new RegExp("^(?=.*[a-zA-Z])[a-zA-Z,()0-9_ ]*$"),
	/**
	 * MPAN = 13 integers
	 */
	MPAN: new RegExp("^[0-9]{13}$"),
	/**
	 * ELEC_MSN = 3 to 10 alphanumeric characers
	 */
	ELEC_MSN: new RegExp("^[0-9a-zA-Z]{3,10}$"),
	/**
	 * MPRN = 6 to 10 integers
	 */
	MPRN: new RegExp("^[0-9]{6,10}$"),
	/**
	 * Only positive numbers including decimals i.e. 9 and 9.9
	 */
	POSITIVE_NUMBERS_ONLY: new RegExp("^[0-9]+(.[0-9]+)?$"),
	/**
	 * Only positive integers
	 */
	POSITIVE_INTEGERS_ONLY: new RegExp("^[0-9]+$"),
	/**
	 * Only numbers including decimals i.e. 9 and 9.9
	 */
	POSITIVE_NUMBERS_UP_TO_ONE_DECIMAL_PLACE_ONLY: new RegExp(
		"^[0-9]+(.[0-9]{1})?$"
	),
	/**
	 * SSC = 4 integers
	 */
	SSC: new RegExp("^[0-9]{4}$"),
	/**
	 * REGISTER_ID = 1 to 2 alphanumeric characters
	 */
	REGISTER_ID: new RegExp("^[0-9a-zA-Z]{1,2}$"),
	/**
	 * SNAC = up to 14 integers
	 */
	SNAC: new RegExp("^[0-9]{1,14}$"),
	/**
	 * METER_ID = 3 to 17 integers
	 */
	METER_ID: new RegExp("^[0-9a-zA-Z]{3,10}$"),
	/**
	 * Up to 7 numbers including one decimal place i.e. 9 and 9.9
	 */
	REGISTER_READING: new RegExp("^[0-9]{1,7}(?:\\.[0-9]{1})?$"),
	/**
	 * Site visit check code is a double digit number with some exceptions i.e. 01, 02, 13, 25, 89
	 */
	SITE_VISIT_CHECK_CODE: new RegExp(
		"^(0[1-9]|1[0-13-9]|[23][0-9]|4[0-4]|5[0-8]|8[8-9])$"
	),
	/**
	 * Reading type is a single letter from the following: A, C, D, F, I, M, O, P, Q, R, S, T, X, Z, W, U, V, Y
	 */
	READING_TYPE: new RegExp("^[A-DFIMOPQRSTXZUWVY]$"),
	/**
	 * Time pattern regime is a 5 digit number like the following: 00001, 00135, 13001, 14151, 99911
	 */
	TIME_PATTERN_REGIME: new RegExp("^[0-9]{5}$"),
};

export const timePatternRegimeCodes = [
	"00001",
	"00002",
	"00003",
	"00004",
	"00005",
	"00006",
	"00007",
	"00008",
	"00009",
	"00010",
	"00011",
	"00012",
	"00013",
	"00014",
	"00015",
	"00016",
	"00017",
	"00018",
	"00019",
	"00020",
	"00021",
	"00022",
	"00023",
	"00024",
	"00025",
	"00026",
	"00027",
	"00028",
	"00029",
	"00030",
	"00031",
	"00032",
	"00033",
	"00034",
	"00035",
	"00036",
	"00037",
	"00038",
	"00039",
	"00040",
	"00041",
	"00042",
	"00043",
	"00044",
	"00045",
	"00046",
	"00047",
	"00048",
	"00049",
	"00050",
	"00051",
	"00052",
	"00053",
	"00054",
	"00055",
	"00056",
	"00057",
	"00058",
	"00059",
	"00060",
	"00061",
	"00062",
	"00063",
	"00064",
	"00065",
	"00066",
	"00067",
	"00068",
	"00069",
	"00070",
	"00071",
	"00072",
	"00073",
	"00074",
	"00075",
	"00076",
	"00077",
	"00078",
	"00079",
	"00080",
	"00081",
	"00082",
	"00083",
	"00084",
	"00085",
	"00086",
	"00087",
	"00088",
	"00089",
	"00090",
	"00091",
	"00092",
	"00093",
	"00094",
	"00095",
	"00096",
	"00097",
	"00098",
	"00099",
	"00100",
	"00101",
	"00102",
	"00103",
	"00104",
	"00105",
	"00106",
	"00107",
	"00108",
	"00109",
	"00110",
	"00111",
	"00112",
	"00113",
	"00114",
	"00115",
	"00116",
	"00117",
	"00118",
	"00119",
	"00120",
	"00121",
	"00122",
	"00123",
	"00124",
	"00125",
	"00126",
	"00127",
	"00128",
	"00129",
	"00130",
	"00131",
	"00132",
	"00133",
	"00134",
	"00135",
	"00136",
	"00137",
	"00138",
	"00139",
	"00140",
	"00141",
	"00142",
	"00143",
	"00144",
	"00145",
	"00146",
	"00147",
	"00148",
	"00149",
	"00150",
	"00151",
	"00152",
	"00153",
	"00154",
	"00155",
	"00156",
	"00157",
	"00158",
	"00159",
	"00160",
	"00161",
	"00162",
	"00163",
	"00164",
	"00165",
	"00166",
	"00167",
	"00168",
	"00169",
	"00170",
	"00171",
	"00172",
	"00173",
	"00174",
	"00175",
	"00176",
	"00177",
	"00178",
	"00179",
	"00180",
	"00181",
	"00182",
	"00183",
	"00184",
	"00185",
	"00186",
	"00187",
	"00188",
	"00189",
	"00190",
	"00191",
	"00192",
	"00193",
	"00194",
	"00195",
	"00196",
	"00197",
	"00198",
	"00199",
	"00200",
	"00201",
	"00202",
	"00203",
	"00204",
	"00205",
	"00206",
	"00207",
	"00208",
	"00209",
	"00210",
	"00211",
	"00212",
	"00213",
	"00214",
	"00215",
	"00216",
	"00217",
	"00218",
	"00219",
	"00220",
	"00221",
	"00222",
	"00223",
	"00224",
	"00225",
	"00226",
	"00227",
	"00228",
	"00229",
	"00230",
	"00231",
	"00232",
	"00233",
	"00234",
	"00235",
	"00236",
	"00237",
	"00238",
	"00239",
	"00240",
	"00241",
	"00242",
	"00243",
	"00244",
	"00245",
	"00246",
	"00247",
	"00248",
	"00249",
	"00250",
	"00251",
	"00252",
	"00253",
	"00254",
	"00255",
	"00256",
	"00257",
	"00258",
	"00259",
	"00260",
	"00261",
	"00262",
	"00263",
	"00264",
	"00265",
	"00266",
	"00267",
	"00268",
	"00269",
	"00270",
	"00271",
	"00272",
	"00273",
	"00274",
	"00275",
	"00276",
	"00277",
	"00278",
	"00279",
	"00280",
	"00281",
	"00282",
	"00283",
	"00284",
	"00285",
	"00286",
	"00287",
	"00288",
	"00289",
	"00290",
	"00291",
	"00292",
	"00293",
	"00294",
	"00295",
	"00296",
	"00297",
	"00298",
	"00299",
	"00300",
	"00301",
	"00302",
	"00303",
	"00304",
	"00305",
	"00306",
	"00307",
	"00308",
	"00309",
	"00310",
	"00311",
	"00312",
	"00313",
	"00314",
	"00315",
	"00316",
	"00317",
	"00318",
	"00319",
	"00320",
	"00321",
	"00322",
	"00323",
	"00324",
	"00325",
	"00326",
	"00327",
	"00328",
	"00329",
	"00330",
	"00331",
	"00332",
	"00333",
	"00334",
	"00335",
	"00336",
	"00337",
	"00338",
	"00339",
	"00340",
	"00341",
	"00342",
	"00343",
	"00344",
	"00345",
	"00346",
	"00347",
	"00348",
	"00349",
	"00350",
	"00351",
	"00352",
	"00353",
	"00354",
	"00355",
	"00356",
	"00357",
	"00358",
	"00359",
	"00360",
	"00361",
	"00362",
	"00363",
	"00364",
	"00365",
	"00366",
	"00367",
	"00368",
	"00369",
	"00370",
	"00371",
	"00372",
	"00373",
	"00374",
	"00375",
	"00376",
	"00377",
	"00378",
	"00379",
	"00380",
	"00381",
	"00382",
	"00383",
	"00384",
	"00385",
	"00386",
	"00387",
	"00388",
	"00389",
	"00390",
	"00391",
	"00392",
	"00393",
	"00394",
	"00395",
	"00396",
	"00397",
	"00398",
	"00399",
	"00400",
	"00401",
	"00402",
	"00403",
	"00404",
	"00405",
	"00406",
	"00407",
	"00408",
	"00409",
	"00410",
	"00411",
	"00412",
	"00413",
	"00414",
	"00415",
	"00416",
	"00417",
	"00418",
	"00419",
	"00420",
	"00421",
	"00422",
	"00423",
	"00424",
	"00425",
	"00426",
	"00427",
	"00428",
	"00429",
	"00430",
	"00431",
	"00432",
	"00433",
	"00434",
	"00435",
	"00436",
	"00437",
	"00438",
	"00439",
	"00440",
	"00441",
	"00442",
	"00443",
	"00444",
	"00445",
	"00446",
	"00447",
	"00448",
	"00449",
	"00450",
	"00451",
	"00452",
	"00453",
	"00454",
	"00455",
	"00456",
	"00457",
	"00458",
	"00459",
	"00460",
	"00461",
	"00462",
	"00463",
	"00464",
	"00465",
	"00466",
	"00467",
	"00468",
	"00469",
	"00470",
	"00471",
	"00472",
	"00473",
	"00474",
	"00475",
	"00476",
	"00477",
	"00478",
	"00479",
	"00480",
	"00481",
	"00482",
	"00483",
	"00484",
	"00485",
	"00486",
	"00487",
	"00488",
	"00489",
	"00490",
	"00491",
	"00492",
	"00493",
	"00494",
	"00495",
	"00496",
	"00497",
	"00498",
	"00499",
	"00500",
	"00501",
	"00502",
	"00503",
	"00504",
	"00505",
	"00506",
	"00507",
	"00508",
	"00509",
	"00510",
	"00511",
	"00512",
	"00513",
	"00514",
	"00515",
	"00516",
	"00517",
	"00518",
	"00519",
	"00520",
	"00521",
	"00522",
	"00523",
	"00524",
	"00525",
	"00526",
	"00527",
	"00528",
	"00529",
	"00530",
	"00531",
	"00532",
	"00533",
	"00534",
	"00535",
	"00536",
	"00537",
	"00538",
	"00539",
	"00540",
	"00541",
	"00542",
	"00543",
	"00544",
	"00546",
	"00547",
	"00548",
	"00549",
	"00550",
	"00551",
	"00552",
	"00555",
	"00556",
	"00557",
	"00558",
	"00559",
	"00560",
	"00561",
	"00562",
	"00563",
	"00564",
	"00565",
	"00566",
	"00567",
	"00568",
	"00569",
	"00570",
	"00571",
	"00572",
	"00573",
	"00574",
	"00575",
	"00576",
	"00577",
	"00578",
	"00579",
	"00580",
	"00581",
	"00582",
	"00583",
	"00586",
	"00587",
	"00588",
	"00589",
	"01000",
	"01001",
	"01002",
	"01003",
	"01004",
	"01005",
	"01006",
	"01007",
	"01008",
	"01009",
	"01010",
	"01011",
	"01012",
	"01013",
	"01014",
	"01015",
	"01016",
	"01017",
	"01018",
	"01019",
	"01020",
	"01021",
	"01022",
	"01023",
	"01024",
	"01025",
	"01027",
	"01028",
	"01029",
	"01030",
	"01032",
	"01034",
	"01035",
	"01036",
	"01037",
	"01038",
	"01039",
	"01040",
	"01041",
	"01042",
	"01043",
	"01044",
	"01045",
	"01046",
	"01047",
	"01048",
	"01049",
	"01050",
	"01051",
	"01052",
	"01053",
	"01054",
	"01055",
	"01056",
	"01057",
	"01058",
	"01059",
	"01060",
	"01061",
	"01062",
	"01063",
	"01064",
	"01065",
	"01066",
	"01067",
	"01068",
	"01069",
	"01070",
	"01071",
	"01072",
	"01073",
	"01074",
	"01075",
	"01076",
	"01077",
	"01078",
	"01079",
	"01080",
	"01081",
	"01082",
	"01083",
	"01084",
	"01085",
	"01086",
	"01087",
	"01088",
	"01089",
	"01090",
	"01091",
	"01092",
	"01093",
	"01094",
	"01095",
	"01096",
	"01097",
	"01098",
	"01099",
	"01100",
	"01101",
	"01102",
	"01103",
	"01104",
	"01105",
	"01106",
	"01107",
	"01108",
	"01109",
	"01110",
	"01111",
	"01112",
	"01113",
	"01114",
	"01115",
	"01116",
	"01118",
	"01119",
	"01120",
	"01121",
	"01122",
	"01123",
	"01124",
	"01125",
	"01126",
	"01127",
	"01128",
	"01129",
	"01130",
	"01131",
	"01132",
	"01133",
	"01134",
	"01135",
	"01136",
	"01137",
	"01138",
	"01139",
	"01140",
	"01141",
	"01142",
	"01143",
	"01144",
	"01145",
	"01146",
	"01147",
	"01148",
	"01149",
	"01150",
	"01151",
	"01152",
	"01153",
	"01154",
	"01155",
	"01156",
	"01157",
	"01161",
	"01162",
	"01163",
	"01167",
	"01168",
	"01169",
	"01170",
	"01171",
	"01172",
	"01173",
	"01174",
	"01175",
	"01176",
	"01177",
	"01178",
	"01179",
	"01180",
	"01181",
	"01182",
	"01183",
	"01184",
	"01185",
	"01186",
	"01189",
	"01190",
	"01191",
	"01192",
	"01193",
	"01194",
	"01200",
	"01201",
	"01202",
	"01203",
	"01204",
	"01205",
	"01206",
	"01207",
	"01208",
	"01209",
	"01210",
	"01211",
	"01212",
	"01213",
	"01214",
	"01215",
	"01216",
	"01217",
	"01218",
	"01219",
	"01220",
	"01221",
	"01222",
	"01223",
	"01224",
	"01225",
	"01226",
	"01227",
	"01228",
	"01229",
	"01233",
	"01234",
	"01235",
	"01236",
	"01237",
	"01238",
	"01239",
	"01240",
	"01241",
	"01242",
	"01243",
	"01244",
	"01245",
	"01246",
	"01247",
	"01248",
	"01249",
	"01250",
	"01251",
	"01252",
	"01253",
	"01254",
	"01255",
	"01256",
	"01257",
	"01260",
	"01261",
	"01262",
	"01263",
	"01265",
	"01266",
	"01267",
	"01268",
	"01269",
	"01270",
	"01271",
	"01272",
	"01273",
	"01274",
	"01275",
	"01276",
	"01277",
	"01278",
	"01279",
	"01280",
	"01284",
	"01285",
	"01286",
	"01287",
	"01288",
	"01289",
	"01290",
	"01291",
	"01292",
	"01293",
	"01294",
	"01295",
	"01296",
	"01297",
	"01298",
	"01299",
	"01300",
	"01307",
	"01308",
	"01309",
	"01310",
	"01311",
	"01312",
	"01313",
	"01315",
	"01316",
	"01317",
	"01319",
	"01320",
	"01321",
	"01323",
	"01324",
	"01325",
	"01326",
	"01327",
	"01328",
	"01329",
	"01330",
	"01331",
	"01332",
	"01333",
	"01334",
	"01335",
	"01336",
	"01337",
	"01338",
	"01339",
	"01340",
	"01341",
	"01342",
	"01343",
	"01344",
	"01345",
	"01346",
	"01347",
	"01348",
	"01349",
	"01350",
	"01351",
	"01352",
	"01353",
	"01354",
	"01355",
	"01356",
	"01357",
	"01358",
	"01359",
	"01360",
	"01361",
	"01362",
	"01363",
	"01364",
	"01365",
	"01366",
	"01367",
	"01368",
	"01371",
	"01372",
	"01375",
	"01376",
	"01377",
	"01378",
	"01379",
	"01380",
	"01381",
	"01382",
	"01383",
	"01384",
	"01385",
	"01386",
	"01392",
	"01393",
	"01394",
	"01395",
	"01396",
	"01397",
	"01398",
	"01399",
	"01400",
	"01401",
	"01402",
	"01403",
	"01404",
	"01405",
	"01406",
	"01407",
	"01408",
	"01409",
	"01410",
	"01411",
	"01412",
	"01413",
	"01414",
	"01415",
	"01416",
	"01417",
	"01418",
	"01419",
	"01420",
	"01421",
	"01422",
	"01423",
	"01424",
	"01425",
	"01426",
	"01427",
	"01428",
	"01429",
	"13001",
	"13002",
	"13003",
	"13004",
	"13005",
	"13006",
	"13007",
	"13008",
	"13009",
	"13010",
	"13011",
	"13012",
	"13013",
	"13014",
	"13015",
	"13016",
	"13017",
	"13018",
	"13019",
	"13020",
	"13021",
	"13022",
	"13023",
	"13024",
	"13025",
	"13026",
	"13027",
	"13028",
	"13032",
	"13033",
	"13034",
	"13035",
	"13036",
	"13037",
	"13038",
	"13039",
	"13040",
	"13041",
	"13042",
	"13043",
	"13044",
	"13045",
	"13046",
	"13047",
	"13048",
	"13049",
	"13050",
	"13051",
	"13052",
	"13053",
	"13054",
	"13055",
	"13056",
	"13057",
	"13058",
	"13059",
	"13060",
	"13061",
	"13062",
	"13063",
	"13064",
	"13065",
	"13066",
	"13067",
	"13068",
	"13069",
	"13070",
	"13071",
	"13072",
	"13073",
	"13074",
	"13075",
	"13076",
	"13077",
	"13078",
	"13079",
	"13080",
	"13081",
	"13082",
	"13083",
	"13084",
	"13085",
	"13086",
	"13087",
	"13088",
	"13089",
	"13090",
	"13091",
	"13092",
	"13093",
	"14001",
	"14002",
	"14003",
	"14004",
	"14005",
	"14006",
	"14007",
	"14008",
	"14009",
	"14010",
	"14011",
	"14012",
	"14013",
	"14014",
	"14015",
	"14016",
	"14017",
	"14018",
	"14019",
	"14020",
	"14021",
	"14022",
	"14023",
	"14024",
	"14025",
	"14026",
	"14027",
	"14028",
	"14029",
	"14030",
	"14031",
	"14032",
	"14033",
	"14034",
	"14035",
	"14036",
	"14037",
	"14038",
	"14039",
	"14040",
	"14041",
	"14042",
	"14043",
	"14044",
	"14045",
	"14046",
	"14047",
	"14048",
	"14049",
	"14050",
	"14051",
	"14052",
	"14053",
	"14054",
	"14055",
	"14056",
	"14057",
	"14058",
	"14059",
	"14060",
	"14061",
	"14062",
	"14063",
	"14064",
	"14065",
	"14066",
	"14067",
	"14068",
	"14069",
	"14070",
	"14071",
	"14072",
	"14073",
	"14074",
	"14075",
	"14076",
	"14077",
	"14078",
	"14079",
	"14080",
	"14081",
	"14082",
	"14083",
	"14084",
	"14085",
	"14086",
	"14087",
	"14088",
	"14089",
	"14090",
	"14091",
	"14092",
	"14093",
	"14094",
	"14095",
	"14096",
	"14097",
	"14098",
	"14099",
	"14100",
	"14101",
	"14102",
	"14103",
	"14104",
	"14105",
	"14106",
	"14107",
	"14108",
	"14110",
	"14111",
	"14112",
	"14113",
	"14114",
	"14115",
	"14116",
	"14117",
	"14118",
	"14119",
	"14120",
	"14121",
	"14122",
	"14123",
	"14124",
	"14125",
	"14126",
	"14127",
	"14128",
	"14129",
	"14130",
	"14131",
	"14132",
	"14133",
	"14134",
	"14135",
	"14136",
	"14137",
	"14138",
	"14139",
	"14140",
	"14141",
	"14142",
	"14143",
	"14144",
	"14145",
	"14146",
	"14147",
	"14148",
	"14149",
	"14150",
	"14151",
	"91011",
	"91012",
	"91013",
	"91021",
	"91022",
	"91023",
	"91111",
	"91112",
	"91113",
	"91121",
	"91122",
	"91123",
	"91211",
	"91212",
	"91213",
	"91221",
	"91222",
	"91223",
	"91311",
	"91312",
	"91313",
	"91321",
	"91322",
	"91323",
	"91411",
	"91412",
	"91413",
	"91421",
	"91422",
	"91423",
	"91511",
	"91512",
	"91513",
	"91521",
	"91522",
	"91523",
	"91611",
	"91612",
	"91613",
	"91621",
	"91622",
	"91623",
	"91711",
	"91712",
	"91713",
	"91721",
	"91722",
	"91723",
	"91811",
	"91812",
	"91813",
	"91821",
	"91822",
	"91823",
	"91911",
	"91912",
	"91913",
	"91921",
	"91922",
	"91923",
	"92011",
	"92012",
	"92013",
	"92021",
	"92022",
	"92023",
	"92111",
	"92112",
	"92113",
	"92121",
	"92122",
	"92123",
	"92211",
	"92212",
	"92213",
	"92221",
	"92222",
	"92223",
	"92311",
	"92312",
	"92313",
	"92321",
	"92322",
	"92323",
	"99911",
];
