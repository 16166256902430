import { IChangeReadFormValues } from "../models/talos";

export const getTotalOfAllCorrectReads = (values: IChangeReadFormValues) => {
	const correctValOne = values.firstRegisterCorrectRead ?? 0;
	const correctValTwo = values.secondRegisterCorrectRead ?? 0;

	return correctValOne + correctValTwo;
};

export const getTotalOfAllIncorrectReads = (values: IChangeReadFormValues) => {
	const incorrectValOne = values.firstRegisterIncorrectRead ?? 0;
	const incorrectValTwo = values.secondRegisterIncorrectRead ?? 0;

	return incorrectValOne + incorrectValTwo;
};

export const getDifferenceBetweenFirstIncorrectAndCorrectReads = (
	values: IChangeReadFormValues
) => {
	const valOne = values.firstRegisterIncorrectRead ?? 0;
	const valTwo = values.firstRegisterCorrectRead ?? 0;

	return valOne - valTwo;
};

export const getDifferenceBetweenSecondIncorrectAndCorrectReads = (
	values: IChangeReadFormValues
) => {
	const valOne = values.secondRegisterIncorrectRead ?? 0;
	const valTwo = values.secondRegisterCorrectRead ?? 0;

	return valOne - valTwo;
};

/**
 * The total of the correct reads and the total of the incorrect reads should be more than 250 units apart.
 * @param values the Chagne read form
 * @returns true is the parameters are incorrect.
 */
export const correctAndIncorrectReadingsAreTooCloseTogether = (
	values: IChangeReadFormValues
) => {
	const sumCorrectReads = getTotalOfAllCorrectReads(values);
	const sumIncorrectReads = getTotalOfAllIncorrectReads(values);
	const diff = sumIncorrectReads - sumCorrectReads;

	return diff >= -250 && diff <= 250;
};

export const roundToFirstDecimal = (num: number) => {
	return Math.round(num * 10) / 10;
};
