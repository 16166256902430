import {
	IMOP04v10,
	IinstalledCorrectingInstalledMetersM24,
	IinstalledMeterRegistersM26,
	IremovedCorrectingRemovedMetersM27,
	IremovedMeterRegistersM28,
	JobType,
} from "../models/outbound";

export const mapMop04FormValuesToRequest = (values: IMOP04v10): IMOP04v10 => {
	const mop04Request: IMOP04v10 = {
		mpanCore: values.mpanCore,
		dateOfAction: values.dateOfAction,
		timeOfAction: new Date(values.timeOfAction).toLocaleTimeString("en-GB"),
		jobType: values.jobType,
		successfulJob: String(values.successfulJob).toLowerCase() === "true",
		supplierId: values.supplierId,
	};

	if (
		([JobType.EXCHANGE, JobType.REMOVE, JobType.INSTALL] as string[]).includes(
			values.jobType
		)
	) {
		mop04Request.originalIncorrectDateOfAction =
			values.originalIncorrectDateOfAction || undefined;
		mop04Request.correctionFlow = values.correctionFlow
			? String(values.correctionFlow) === "true"
			: undefined;
		mop04Request.dccConnected = values.dccConnected
			? String(values.dccConnected) === "true"
			: undefined;
		mop04Request.fieldForceName = values.fieldForceName || undefined;
		mop04Request.standardSettlementConfigurationId =
			values.standardSettlementConfigurationId || undefined;
		mop04Request.meteringSystemNonSettlementFunctionalityCode =
			values.meteringSystemNonSettlementFunctionalityCode || undefined;
		mop04Request.siteVisitCheckCode = values.siteVisitCheckCode || undefined;
		mop04Request.complexSiteIndicator = values.complexSiteIndicator
			? String(values.complexSiteIndicator) === "true"
			: undefined;
		mop04Request.meterEquipmentServiceLocation =
			values.meterEquipmentServiceLocation || undefined;
		mop04Request.measurementClassId = values.measurementClassId || undefined;
		mop04Request.meterCOP = values.meterCOP || undefined;
		mop04Request.meterCOPIssueNumber = values.meterCOPIssueNumber
			? Number(values.meterCOPIssueNumber)
			: undefined;
		mop04Request.eventIndicator = values.eventIndicator || undefined;
		mop04Request.numberOfPhases = values.numberOfPhases
			? Number(values.numberOfPhases)
			: undefined;
		mop04Request.systemVoltage = values.systemVoltage
			? Number(values.systemVoltage)
			: undefined;
		mop04Request.additionalInformation =
			values.additionalInformation || undefined;
		mop04Request.remoteDisableEnableCapability =
			values.remoteDisableEnableCapability
				? String(values.remoteDisableEnableCapability) === "true"
				: undefined;
		mop04Request.measurementTransformerCommissioningCompleted =
			values.measurementTransformerCommissioningCompleted
				? String(values.measurementTransformerCommissioningCompleted) === "true"
				: undefined;
		mop04Request.moaCommissioningCompleted = values.moaCommissioningCompleted
			? String(values.moaCommissioningCompleted) === "true"
			: undefined;
		mop04Request.overallAccuracyOfMeteringSystem =
			values.overallAccuracyOfMeteringSystem
				? String(values.overallAccuracyOfMeteringSystem) === "true"
				: undefined;
		mop04Request.dateOfInstallationRemovalOfTimingDevice =
			values.dateOfInstallationRemovalOfTimingDevice || undefined;
		mop04Request.natureOfMaintenance = values.natureOfMaintenance || undefined;
		mop04Request.ihdInstallStatus = values.ihdInstallStatus || undefined;
	}

	mop04Request.installedCorrectingInstalledMeters =
		values.jobType === JobType.INSTALL || values.jobType === JobType.EXCHANGE
			? values.installedCorrectingInstalledMeters?.map(
					(
						installedCorrectingInstalledMeter
					): IinstalledCorrectingInstalledMetersM24 => {
						return {
							meterConfigCode:
								installedCorrectingInstalledMeter.meterConfigCode,
							installedMeterId:
								installedCorrectingInstalledMeter.installedMeterId,
							meterLocation: installedCorrectingInstalledMeter.meterLocation,
							originalIncorrectMeterId:
								installedCorrectingInstalledMeter.originalIncorrectMeterId ||
								undefined,
							phaseWire:
								installedCorrectingInstalledMeter.phaseWire || undefined,
							feederStatus:
								installedCorrectingInstalledMeter.feederStatus || undefined,
							ctRatio: installedCorrectingInstalledMeter.ctRatio || undefined,
							vtRatio: installedCorrectingInstalledMeter.vtRatio || undefined,
							totalDebt:
								Number(installedCorrectingInstalledMeter.totalDebt) ||
								undefined,
							debtRecoveryRate:
								Number(installedCorrectingInstalledMeter.debtRecoveryRate) ||
								undefined,
							emergencyCredit:
								Number(installedCorrectingInstalledMeter.emergencyCredit) ||
								undefined,
							initialCredit:
								Number(installedCorrectingInstalledMeter.initialCredit) ||
								undefined,
							standingCharge:
								Number(installedCorrectingInstalledMeter.standingCharge) ||
								undefined,
							meterAssetProviderId:
								installedCorrectingInstalledMeter.meterAssetProviderId ||
								undefined,
							timingDeviceId:
								installedCorrectingInstalledMeter.timingDeviceId || undefined,
							meterAccuracyClass:
								installedCorrectingInstalledMeter.meterAccuracyClass ||
								undefined,
							compensationAppliedToMeter:
								installedCorrectingInstalledMeter.compensationAppliedToMeter
									? String(
											installedCorrectingInstalledMeter.compensationAppliedToMeter
									  ) === "true"
									: undefined,
							numberOfDisplayedRegisterDigits:
								Number(
									installedCorrectingInstalledMeter.numberOfDisplayedRegisterDigits
								) || undefined,
							installedMeterRegisters:
								installedCorrectingInstalledMeter.installedMeterRegisters.map(
									(installedMeterRegisters): IinstalledMeterRegistersM26 => {
										return {
											meterRegisterId: installedMeterRegisters.meterRegisterId,
											registerReading: Number(
												installedMeterRegisters.registerReading
											),
											outstationId:
												installedMeterRegisters.outstationId || undefined,
											outstationMultiplier:
												Number(installedMeterRegisters.outstationMultiplier) ||
												undefined,
											natureOfMaintenance:
												installedMeterRegisters.natureOfMaintenance ||
												undefined,
											channelNumber:
												installedMeterRegisters.channelNumber || undefined,
											pulseMultiplier:
												Number(installedMeterRegisters.pulseMultiplier) ||
												undefined,
											meterRegisterMultiplier:
												Number(
													installedMeterRegisters.meterRegisterMultiplier
												) || undefined,
											numberOfRegisterDigits:
												Number(
													installedMeterRegisters.numberOfRegisterDigits
												) || undefined,
											associatedMeterId:
												installedMeterRegisters.associatedMeterId || undefined,
											associatedMeterRegisterId:
												installedMeterRegisters.associatedMeterRegisterId ||
												undefined,
											settlementIndicator:
												installedMeterRegisters.settlementIndicator ||
												undefined,
											measurementQuantityId:
												installedMeterRegisters.measurementQuantityId ||
												undefined,
										};
									}
								),
						};
					}
			  )
			: undefined;

	mop04Request.removedCorrectingRemovedMeters =
		values.jobType === JobType.REMOVE || values.jobType === JobType.EXCHANGE
			? values.removedCorrectingRemovedMeters?.map(
					(
						removedCorrectingRemovedMeters
					): IremovedCorrectingRemovedMetersM27 => {
						return {
							removedMeterId: removedCorrectingRemovedMeters.removedMeterId,
							originalIncorrectRemovedMeterId:
								removedCorrectingRemovedMeters.originalIncorrectRemovedMeterId ||
								undefined,
							creditBalance:
								Number(removedCorrectingRemovedMeters.creditBalance) ||
								undefined,
							standingCharge:
								Number(removedCorrectingRemovedMeters.standingCharge) ||
								undefined,
							totalCreditAccepted:
								Number(removedCorrectingRemovedMeters.totalCreditAccepted) ||
								undefined,
							totalDebtOutstanding:
								Number(removedCorrectingRemovedMeters.totalDebtOutstanding) ||
								undefined,
							debtRecoveryRate:
								Number(removedCorrectingRemovedMeters.debtRecoveryRate) ||
								undefined,
							emergencyCreditStatus:
								removedCorrectingRemovedMeters.emergencyCreditStatus
									? String(
											removedCorrectingRemovedMeters.emergencyCreditStatus
									  ) === "true"
									: undefined,
							totalTokensInserted:
								Number(removedCorrectingRemovedMeters.totalTokensInserted) ||
								undefined,
							timingDeviceId:
								removedCorrectingRemovedMeters.timingDeviceId || undefined,
							numberOfDisplayedRegisterDigits:
								Number(
									removedCorrectingRemovedMeters.numberOfDisplayedRegisterDigits
								) || undefined,
							removedMeterRegisters:
								removedCorrectingRemovedMeters.removedMeterRegisters.map(
									(removedMeterRegisters): IremovedMeterRegistersM28 => {
										return {
											meterRegisterId: removedMeterRegisters.meterRegisterId,
											registerReading:
												Number(removedMeterRegisters.registerReading) ||
												undefined,
											registerDescription:
												removedMeterRegisters.registerDescription || undefined,
											prepaymentUnitRate:
												Number(removedMeterRegisters.prepaymentUnitRate) ||
												undefined,
											natureOfMaintenance:
												removedMeterRegisters.natureOfMaintenance || undefined,
										};
									}
								),
						};
					}
			  )
			: undefined;

	return mop04Request;
};
