import React from "react";

import {
	alpha,
	Box,
	Drawer,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Toolbar,
	Typography,
	useTheme,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { SxProps } from "@mui/material/styles";

import { useAuth } from "../../auth";
import { ROLES } from "../../utilities";
import { TalosFlags, useTalosFlags } from "../../flags";

interface LeftMenuProps {
	open: boolean;
	onClose: () => void;
}

interface ITalosMenuGroup {
	title: string;
	items: ITalosMenuItem[];
	titleSxProps?: SxProps;
}

interface ITalosMenuItem {
	displayText: string;
	href: string;
	flag?: keyof TalosFlags;
	role?: ROLES;
}

const menuItemGroups: ITalosMenuGroup[] = [
	{
		title: "",
		items: [
			{
				displayText: "Home",
				href: "/",
			},
			{
				displayText: "Utilities",
				href: "/reads-calculations",
				flag: "uiEnableUiUtilitiesSection",
				role: ROLES.UTILITIES_ACCESS,
			},
		],
	},
	{
		title: "MDS Bookings",
		items: [
			{
				displayText: "Bookings",
				href: "/booking-requests",
				role: ROLES.BOOKING_REQUEST_READ,
			},
			{
				displayText: "Add Booking",
				href: "/new-booking-request",
				role: ROLES.BOOKING_REQUEST_WRITE,
			},
		],
	},
	{
		title: "Change Reads",
		items: [
			{
				displayText: "Requests",
				href: "/change-read-requests",
				role: ROLES.CHANGE_READ_READ,
			},
			{
				displayText: "Add Request",
				href: "/add-change-read-request",
				role: ROLES.CHANGE_READ_WRITE,
			},
		],
	},
	{
		title: "Force Validate Reads",
		items: [
			{
				displayText: "Add request",
				href: "/add-force-validate-read",
				role: ROLES.SETTLEMENTS_READS_FVR_WRITE,
				flag: "uiEnableForceValidateReads",
			},
			{
				displayText: "Requests",
				href: "/force-validate-read-requests",
				role: ROLES.SETTLEMENTS_READS_FVR_READ,
				flag: "uiEnableForceValidateReads",
			},
		],
	},
	{
		title: "DMEX Reads",
		items: [
			{
				displayText: "Add request",
				href: "/add-dmex",
				role: ROLES.SETTLEMENTS_READS_DMEX_WRITE,
				flag: "uiEnableDmex",
			},
			{
				displayText: "Requests",
				href: "/dmex-requests",
				role: ROLES.SETTLEMENTS_READS_DMEX_READ,
				flag: "uiEnableDmex",
			},
		],
	},
	{
		title: "Update Specific Registration Detail (D0205)",
		items: [
			{
				displayText: "Flows",
				href: "/d0205-flows",
				flag: "uiEnableUiFlows",
				role: ROLES.FLOWS_USER,
			},
			{
				displayText: "Submit Flow",
				href: "/add-d0205-flow",
				role: ROLES.FLOWS_ADMIN,
			},
		],
	},
	{
		title: "D0052",
		items: [
			{
				displayText: "Flows",
				href: "/d0052-flows",
				role: ROLES.FLOWS_USER,
				flag: "uiEnableUiFlows",
			},
			{
				displayText: "Add Flow",
				href: "/add-d0052-flow",
				role: ROLES.FLOWS_ADMIN,
				flag: "uiEnableUiFlows",
			},
		],
	},
	{
		title: "Gas Read Replacement",
		items: [
			{
				displayText: "Gas Read Replacement Requests",
				href: "/gas-read-replacement-requests",
				flag: "uiEnableUtFlow",
				role: ROLES.FLOWS_USER,
			},
			{
				displayText: "New Gas Read Replacement Request",
				href: "/add-gas-read-replacement-request",
				flag: "uiEnableUtFlow",
				role: ROLES.FLOWS_ADMIN,
			},
		],
	},
	{
		title: "S0003",
		items: [
			{
				displayText: "Consumption Update Requests",
				href: "/consumption-update-requests",
				role: ROLES.FLOWS_USER,
				flag: "uiEnableMmfcFlows",
			},
			{
				displayText: "New Consumption Update Request",
				href: "/add-consumption-update-request",
				role: ROLES.FLOWS_ADMIN,
				flag: "uiEnableMmfcFlows",
			},
			{
				displayText: "Consumption Amendment Requests",
				href: "/consumption-amendment-requests",
				role: ROLES.FLOWS_USER,
				flag: "uiEnableMmfcFlows",
			},
			{
				displayText: "New Consumption Amendment Request",
				href: "/add-consumption-amendment-request",
				role: ROLES.FLOWS_ADMIN,
				flag: "uiEnableMmfcFlows",
			},
			{
				displayText: "S33 Flows",
				href: "/meter-readings-to-industry-and-read-overrides-requests",
				role: ROLES.FLOWS_USER,
				flag: "uiEnableMmfcFlows",
			},
			{
				displayText: "Send Meter Reading to Industry",
				href: "/add-meter-reading-to-industry-request",
				role: ROLES.FLOWS_ADMIN,
				flag: "uiEnableMmfcFlows",
			},
			{
				displayText: "Send Read Override",
				href: "/add-read-override-request",
				role: ROLES.FLOWS_ADMIN,
				flag: "uiEnableMmfcFlows",
			},
		],
	},
	{
		title: "Export Registrations",
		items: [
			{
				displayText: "Migrations",
				href: "/export/migrations",
				role: ROLES.EXPORT_REGISTRATION_BULK_WRITE,
			},
			{
				displayText: "Registrations",
				href: "/export/registrations",
				role: ROLES.EXPORT_REGISTRATION_READ,
			},
			{
				displayText: "ECOES Import Plan",
				href: "/export/ecoes",
				role: ROLES.EXPORT_REGISTRATION_BULK_WRITE,
			},
		],
	},
	{
		title: "Outbound",
		items: [
			{
				displayText: "Submit MOP04",
				href: "/outbound/submit-mop04",
				role: ROLES.OUTBOUND_MTD_WRITE,
				flag: "uiEnableMhhsRequests",
			},
		],
	},
	{
		title: "Change of Registration Data",
		items: [
			{
				displayText: "Legacy Data Item",
				href: "/change-registration/legacy-data",
				role: ROLES.METERING_CHANGE_OF_REGISTRATION_WRITE,
				flag: "uiEnableLegacyDataUpdate",
			},
			{
				displayText: "IHD Status",
				href: "/change-registration/ihd-status",
				role: ROLES.METERING_CHANGE_OF_REGISTRATION_WRITE,
				flag: "uiEnableMhhsRequests",
			},
			{
				displayText: "Consent Granularity",
				href: "/change-registration/consent-granularity",
				role: ROLES.METERING_CHANGE_OF_REGISTRATION_WRITE,
				flag: "uiEnableMhhsRequests",
			},
			{
				displayText: "Supply Deactivation",
				href: "/change-registration/request-supply-deactivation",
				role: ROLES.METERING_CHANGE_OF_REGISTRATION_WRITE,
				flag: "uiEnableMhhsRequests",
			},
			{
				displayText: "Supply Disconnection",
				href: "/change-registration/request-supply-disconnection",
				role: ROLES.METERING_CHANGE_OF_REGISTRATION_WRITE,
				flag: "uiEnableMhhsRequests",
			},
			{
				displayText: "Energisation Status",
				href: "/change-registration/update-energisation-status",
				role: ROLES.METERING_CHANGE_OF_REGISTRATION_WRITE,
				flag: "uiEnableEnergisationStatusUpdate",
			},
			{
				displayText: "Domestic Premise Indicator Update Requests",
				href: "/domestic-premise-indicator-update-requests",
				role: ROLES.FLOWS_USER,
				flag: "uiEnableMhhsRequests",
			},
			{
				displayText: "Update Domestic Premise Indicator",
				href: "/update-domestic-premise-indicator",
				role: ROLES.FLOWS_USER,
				flag: "uiEnableMhhsRequests",
			},
			{
				displayText: "Meter Point Address Update Requests",
				href: "/meter-point-address-update-requests",
				role: ROLES.FLOWS_USER,
				flag: "uiEnableMhhsRequests",
			},
			{
				displayText: "Update Meter Point Address",
				href: "/update-meter-point-address",
				role: ROLES.FLOWS_USER,
				flag: "uiEnableMhhsRequests",
			},
			{
				displayText: "Install/Remove Meter/s",
				href: "/change-registration/request-install-remove-meter",
				role: ROLES.METERING_CHANGE_OF_REGISTRATION_WRITE,
			},
		],
	},
	{
		title: "Change of MPAN Relationship",
		items: [
			{
				displayText: "Processes",
				href: "/mpan-relationship-processes",
				role: ROLES.MPAN_RELATIONSHIP_WRITE,
			},
		],
	},
	{
		title: "MHHS Testing",
		items: [
			{
				displayText: "Inbound Flows Testing",
				href: "/inbound-flow-testing-processes",
				role: ROLES.INBOUND_TESTING_WRITE,
			},
		],
	},
];

const DRAWER_WIDTH = 240;

export const LeftMenu: React.FC<LeftMenuProps> = (props: LeftMenuProps) => {
	const flags = useTalosFlags();
	const theme = useTheme();
	const authContext = useAuth();

	const shouldRender = (li: ITalosMenuItem) => {
		if (li.role && !authContext.hasRole(li.role)) return false;
		if (li.flag && !flags[li.flag]) return false;
		return true;
	};

	const shouldHideHeader = (group: ITalosMenuGroup) => {
		if (!group.items) {
			return true;
		}

		return group.items.every((item) => !shouldRender(item));
	};

	const renderListItem = (li: ITalosMenuItem, idx: number) => {
		return shouldRender(li) ? (
			<ListItem disablePadding key={`left-list-item-${idx}`}>
				<NavLink to={li.href} style={{ textDecoration: "none" }}>
					{({ isActive }) => (
						<ListItemButton
							sx={{
								color: theme.palette.talos.semantic.message.light,
								borderRadius: 1,
								padding: theme.spacing(0.25, 1),
								margin: theme.spacing(0.2),
								backgroundColor: isActive
									? alpha(theme.palette.common.white, 0.4)
									: null,
								"&:hover": {
									backgroundColor: alpha(theme.palette.common.white, 0.2),
								},
							}}
						>
							<ListItemText primary={li.displayText} />
						</ListItemButton>
					)}
				</NavLink>
			</ListItem>
		) : null;
	};

	return authContext.hasTalosPermissions() ? (
		<Drawer
			variant="persistent"
			open={props.open}
			onClose={props.onClose}
			data-cy="left-menu-drawer"
			sx={{
				width: props.open ? DRAWER_WIDTH : 0,
				flexShrink: 0,
				["& .MuiDrawer-paper"]: {
					width: DRAWER_WIDTH,
					boxSizing: "border-box",
				},
				transition: theme.transitions.create("width", {
					easing: theme.transitions.easing.easeIn,
					duration: theme.transitions.duration.enteringScreen,
				}),
			}}
			PaperProps={{
				sx: {
					backgroundColor: theme.palette.talos.semantic.message.black,
					width: "100%",
					padding: theme.spacing(6, 2, 0),
					color: theme.palette.talos.semantic.message.white,
				},
			}}
		>
			<Toolbar />

			<Box sx={{ overflow: "auto" }} data-cy="left-drawer-scroll">
				{menuItemGroups.map(
					(group, idx) =>
						!shouldHideHeader(group) && (
							<React.Fragment key={`${idx}_${group.title}`}>
								<List
									key={`group_${idx}`}
									data-cy={`group_${idx}`}
									sx={{ color: theme.palette.talos.semantic.message.light }}
								>
									<ListItem disablePadding>
										<ListItemText
											primary={
												<Typography
													variant="h2"
													sx={Object.assign(
														{
															color: theme.palette.talos.semantic.message.light,
															wordWrap: "true",
															padding: theme.spacing(1),
														},
														group.titleSxProps
													)}
												>
													{group.title}
												</Typography>
											}
										/>
									</ListItem>
									{group.items.map((li, idx) => renderListItem(li, idx))}
								</List>
							</React.Fragment>
						)
				)}
			</Box>
		</Drawer>
	) : null;
};
