import { AxiosResponse } from "axios";

import { addD0205FlowRequest } from "../../api/talos";
import {
	mapFormToValues,
	AddD0205FlowForm,
} from "../../components/d0205/add-d0205-flow-form";
import {
	IAddD0205Flow,
	initialAddD0205FlowFormValues,
} from "../../models/talos/d0205-flows";
import { AdditionPage } from "../addition-page";

export const AddD0205Flow = () => (
	<AdditionPage<IAddD0205Flow, IAddD0205Flow, AxiosResponse>
		mapFormToValues={mapFormToValues}
		header="Update Specific Registration Detail"
		initialValues={initialAddD0205FlowFormValues}
		summary="Fill in this form to send a new request to update specific registration detail (D0205)."
		additionFormKey="add-d0205-flow"
		additionInstanceName="D0205 Flow"
		AddInstanceForm={AddD0205FlowForm}
		addInstanceRequest={addD0205FlowRequest}
	/>
);
