import * as Yup from "yup";

import { API_TYPES } from "../../api/talos";
import { generateFormPage } from "../../form-generator";
import { SI2FlowsRequest } from "../../models/talos/si2-flows";
import { postCodes } from "../../utilities";

export interface SI2FlowFormValues {
	mpan: string;
	addressLine1: string;
	addressLine2: string;
	addressLine3: string;
	addressLine4: string;
	addressLine5: string;
	addressLine6: string;
	postCode: string;
	additionalInformation: string;
}

export const AddSI2Flow = generateFormPage<SI2FlowsRequest, SI2FlowFormValues>(
	{
		formKey: "add-si2-flow",
		title: "Meter Point Address Update",
		header: "Update Meter Point Address",
		underlyingFlowName: "S0003_SI2",
		fields: [
			{
				fieldName: "mpan",
				label: "MPAN",
				componentType: "mpan",
				required: true,
			},
			{
				fieldName: "addressLine1",
				label: "Address Line 1 (Organisation)",
				maxLength: 40,
				componentType: "string",
			},
			{
				fieldName: "addressLine2",
				label: "Address Line 2 (Flat Number)",
				maxLength: 40,
				componentType: "string",
			},
			{
				fieldName: "addressLine3",
				label: "Address Line 3 (Building Number or Name)",
				maxLength: 40,
				componentType: "string",
				required: true,
			},
			{
				fieldName: "addressLine4",
				label: "Address Line 4 (Street Name)",
				maxLength: 40,
				componentType: "string",
				required: true,
			},
			{
				fieldName: "addressLine5",
				label: "Address Line 5 (Dependent Locality)",
				maxLength: 40,
				componentType: "string",
			},
			{
				fieldName: "addressLine6",
				label: "Address Line 6 (Town)",
				maxLength: 40,
				componentType: "string",
				required: true,
			},
			{
				fieldName: "postCode",
				label: "Postcode",
				maxLength: 10,
				componentType: "string",
				required: true,
				customValidation: () =>
					Yup.string()
						.matches(
							postCodes.UK_POST_CODE,
							"Postcode must be a valid UK postcode"
						)
						.required("Postcode is required"),
			},
			{
				fieldName: "additionalInformation",
				label: "Additional Info",
				maxLength: 200,
				componentType: "string",
			},
		],
		listPage: {
			subject: "updates",
			link: "/meter-point-address-update-requests",
			title: "Meter Point Address Updates",
		},
	},
	{ path: "si2", apiType: API_TYPES.EXCEPTIONS }
);
