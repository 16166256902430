import React from "react";

import { useFormikContext } from "formik";
import { format } from "date-fns";

import {
	API_TYPES,
	getDMEXRequests,
	SETTLEMENTS_READS_TYPES,
} from "../../api/talos";
import { generateFormPage } from "../../form-generator";
import { COMMON_FIELDS } from "../../form-generator/form-component.generator";
import {
	DMEXFormValues,
	AddDMEXReadsRequest,
	DMEXResponse,
} from "../../models/talos/dmex";
import { useRecentSettlementsAlert } from "../../utilities/settlements-reads.helpers";
import {
	NEWER_THAN_292_WORKING_DAYS_MSG,
	PAST,
	XWorkdaysFromDate,
} from "../../utilities";

const mapFormToValues = ({
	mpan,
	msn,
	readDate,
	reg1Id,
	reg1Final,
	reg1Install,
	reg2Id,
	reg2Final,
	reg2Install,
	hasSecondRegister,
}: DMEXFormValues): AddDMEXReadsRequest => {
	const minimumRequest = {
		mpan: Number(mpan),
		msn: msn,
		readDate: format(readDate, "yyyy-MM-dd"),
		reg1Data: { regId: reg1Id, final: reg1Final, install: reg1Install },
	};

	return hasSecondRegister === "yes" &&
		reg2Id != undefined &&
		reg2Final != undefined &&
		reg2Install != undefined
		? {
				...minimumRequest,
				reg2Data: { regId: reg2Id, final: reg2Final, install: reg2Install },
		  }
		: minimumRequest;
};

const minimumReadDate = () => XWorkdaysFromDate(new Date(), 292, PAST);

export const AddDMEXRequest = generateFormPage<
	AddDMEXReadsRequest,
	DMEXFormValues
>(
	{
		formKey: "add-dmex-request",
		title: "DMEX",
		mapFormToValues,
		fields: [
			{
				componentType: "section",
				fields: [
					COMMON_FIELDS.MPAN,
					{
						...COMMON_FIELDS.MSN,
						required: true,
					},
					{
						label: "Read Date",
						fieldName: "readDate",
						componentType: "date",
						required: true,
						// Read date must be in the past 14 months.
						datePickerProps: {
							disableFuture: true,
							minDate: minimumReadDate(),
						},
						customValidation: (schema) =>
							schema
								.min(minimumReadDate(), NEWER_THAN_292_WORKING_DAYS_MSG)
								.max(new Date(), NEWER_THAN_292_WORKING_DAYS_MSG),
					},
					{
						label: "Register 1 ID",
						fieldName: "reg1Id",
						componentType: "string",
						required: true,
						minLength: 1,
						maxLength: 2,
					},
					{
						...COMMON_FIELDS.REGISTER_READ,
						label: "Register 1 Final Read",
						fieldName: "reg1Final",
						required: true,
					},
					{
						...COMMON_FIELDS.REGISTER_READ,
						label: "Register 1 Install Read",
						fieldName: "reg1Install",
						required: true,
					},
					{
						label: "Second register?",
						fieldName: "hasSecondRegister",
						componentType: "radio",
						required: true,
						defaultValue: "no",
						options: [
							{ label: "Yes", value: "yes" },
							{ label: "No", value: "no" },
						],
					},
				],
			},
			{
				componentType: "section",
				title: "Register 2",
				excludeFromForm: (values) => values["hasSecondRegister"] != "yes",
				fields: [
					{
						label: "Register 2 ID",
						fieldName: "reg2Id",
						componentType: "string",
						required: true,
						minLength: 1,
						maxLength: 2,
					},
					{
						...COMMON_FIELDS.REGISTER_READ,
						label: "Register 2 Final Read",
						fieldName: "reg2Final",
						required: true,
					},
					{
						...COMMON_FIELDS.REGISTER_READ,
						label: "Register 2 Install Read",
						fieldName: "reg2Install",
						required: true,
					},
				],
			},
		],
		listPage: {
			subject: "dmex requests",
			title: "DMEX Requests",
			link: "/dmex-requests",
		},
		FormWrapper: ({ children }: { children: React.ReactNode }) => {
			const { values } = useFormikContext<AddDMEXReadsRequest>();
			const mpan = values.mpan.toString();

			useRecentSettlementsAlert<DMEXResponse>(
				mpan,
				SETTLEMENTS_READS_TYPES.DMEX,
				getDMEXRequests
			);

			return <>{children}</>;
		},
	},
	{ apiType: API_TYPES.SETTLEMENTS_READS, path: "dmex" }
);
