import React from "react";

import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	useTheme,
} from "@mui/material";

import { ChangeReadResponse } from "../../models/talos";
interface IProps {
	rowData: ChangeReadResponse[];
}

export const ChangeReadsTable: React.FC<IProps> = (props: IProps) => {
	const theme = useTheme();

	return (
		<TableContainer
			component={Paper}
			sx={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(3) }}
		>
			<Table size="small" data-cy="change-reads-table">
				<TableHead>
					<TableRow sx={{ background: "#efefef" }}>
						<TableCell colSpan={7}></TableCell>
						<TableCell
							colSpan={3}
							sx={{ textAlign: "center", borderLeft: "1px solid grey" }}
						>
							First Register
						</TableCell>
						<TableCell
							colSpan={3}
							sx={{
								textAlign: "center",
								borderLeft: "1px solid grey",
								borderRight: "1px solid grey",
							}}
						>
							Second Register
						</TableCell>
					</TableRow>
					<TableRow sx={{ background: "#efefef" }}>
						<TableCell rowSpan={2}>STATUS</TableCell>
						<TableCell>Agent Name</TableCell>
						<TableCell>Date Raised</TableCell>
						<TableCell>MPAN</TableCell>
						<TableCell>MSN</TableCell>
						<TableCell>Read Date</TableCell>
						<TableCell>Corrected Read Type</TableCell>
						<TableCell
							sx={{ borderLeft: "1px solid grey", textAlign: "center" }}
						>
							ID
						</TableCell>
						<TableCell>Incorrect</TableCell>
						<TableCell>Correct</TableCell>
						<TableCell
							sx={{ borderLeft: "1px solid grey", textAlign: "center" }}
						>
							ID
						</TableCell>
						<TableCell>Incorrect</TableCell>
						<TableCell sx={{ borderRight: "1px solid grey" }}>
							Correct
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{props.rowData.map((result) => (
						<TableRow key={result.id}>
							<TableCell>{result.status}</TableCell>
							<TableCell>{result.agentName}</TableCell>
							<TableCell>{result.createdAt}</TableCell>
							<TableCell>{result.mpan}</TableCell>
							<TableCell>{result.msn}</TableCell>
							<TableCell>{result.readDate}</TableCell>
							<TableCell align="center">{result.correctReadType}</TableCell>
							<TableCell align="center" sx={{ borderLeft: "1px solid grey" }}>
								{result.reg1Data.regId}
							</TableCell>
							<TableCell align="center">
								{result.reg1Data.regIncorrectRead}
							</TableCell>
							<TableCell align="center">
								{result.reg1Data.regCorrectRead}
							</TableCell>
							<TableCell align="center" sx={{ borderLeft: "1px solid grey" }}>
								{result.reg2Data?.regId}
							</TableCell>
							<TableCell align="center">
								{result.reg2Data?.regIncorrectRead}
							</TableCell>
							<TableCell align="center" sx={{ borderRight: "1px solid grey" }}>
								{result.reg2Data?.regCorrectRead}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
