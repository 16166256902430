import * as Yup from "yup";

import {
	dateIsNotFuture,
	dateIsNotValid,
	MAX_REG_ID,
	maxLengthValidationMessage,
	NEWER_THAN_292_WORKING_DAYS_MSG,
	PAST,
	REGISTER_READING_MSG,
	usefulRegex,
	XWorkdaysFromDate,
} from "../../utilities";

export const formValidationSchema = Yup.object().shape({
	mpan: Yup.string()
		.matches(usefulRegex.MPAN, "MPAN must be a 13 digit integer")
		.required("MPAN is a required field"),
	msn: Yup.string()
		.matches(
			usefulRegex.ELEC_MSN,
			"MSN must be between 3 and 10 alphanumeric characters"
		)
		.required("MSN is a required field"),
	readDate: Yup.string()
		.required("Read Date is a required field")
		.test(
			"read-date-is-not-future",
			"Read Date shouldn't be in the future",
			dateIsNotFuture
		)
		.test(
			"read-date-is-not-valid",
			"Read date should be a valid date",
			dateIsNotValid
		)
		.test("read-date-not-too-old", NEWER_THAN_292_WORKING_DAYS_MSG, (value) => {
			if (!value) return false; // Ensure value is provided

			const today = new Date();
			const oldestAllowedDate = XWorkdaysFromDate(today, 292, PAST);
			const readDate = new Date(value);

			return readDate >= oldestAllowedDate;
		}),
	correctReadType: Yup.string().required(
		"Correct Read Type is a required field"
	),
	firstRegisterId: Yup.string()
		.matches(
			usefulRegex.REGISTER_ID,
			"Register 1 ID must be at most 2 alphanumeric characters"
		)
		.required("Register 1 ID is a required field"),
	subRegisters: Yup.string().required(),
	secondRegisterId: Yup.string().when("subRegisters", {
		is: (value: string) => value === "Y",
		then: (schema) =>
			schema
				.matches(
					usefulRegex.REGISTER_ID,
					"Register 2 ID must be at most 2 alphanumeric characters"
				)
				.required("Register 2 ID is a required field")
				.max(
					MAX_REG_ID,
					maxLengthValidationMessage(MAX_REG_ID, "Second Register ID")
				),
	}),
	firstRegisterIncorrectRead: Yup.number()
		.nullable()
		.transform((curr, orig) => (orig === "" ? null : curr))
		.required("Incorrect Read 1 is a required field")
		.test("frir-1-dp", REGISTER_READING_MSG, (value: number | null) =>
			value ? usefulRegex.REGISTER_READING.test(value.toString()) : true
		),
	secondRegisterIncorrectRead: Yup.number()
		.nullable()
		.transform((curr, orig) => (orig === "" ? null : curr))
		.when("subRegisters", {
			is: (value: string) => value === "Y",
			then: (schema) =>
				schema
					.required("Incorrect Read 2 is required for two rate meters")
					.test("srir-1-dp", REGISTER_READING_MSG, (value: number | "") =>
						value ? usefulRegex.REGISTER_READING.test(value.toString()) : true
					),
		}),
	firstRegisterCorrectRead: Yup.number()
		.nullable()
		.transform((curr, orig) => (orig === "" ? null : curr))
		.required("Correct Read 1 is a required field")
		.test("frcr-1-dp", REGISTER_READING_MSG, (value: number | "") =>
			value ? usefulRegex.REGISTER_READING.test(value.toString()) : true
		),
	secondRegisterCorrectRead: Yup.number()
		.nullable()
		.transform((curr, orig) => (orig === "" ? null : curr))
		.when("subRegisters", {
			is: (value: string) => value === "Y",
			then: (schema) =>
				schema
					.required("Correct Read 2 is required for two rate meters")
					.test("srcr-1-dp", REGISTER_READING_MSG, (value: number | "") =>
						value ? usefulRegex.REGISTER_READING.test(value.toString()) : true
					),
		}),
});
