import { formatJavaStyleDateString } from "../../utilities";
import { getMW2Flows } from "../../api/talos";
import { MW2FlowsResponse } from "../../models/talos/mw2-flows";
import { flowsTable } from "../../components/tables/flows-table";
import { PagedRequestsPage } from "../requests-list-page";

const responseKey = (key: string): key is keyof MW2FlowsResponse =>
	[
		"id",
		"flowId",
		"userId",
		"mpan",
		"status",
		"cause",
		"flowType",
		"createdAt",
		"updatedAt",
	].includes(key);

const responseDataKey = (key: string): key is keyof MW2FlowsResponse["data"] =>
	[
		"mpan",
		"supplierNotificationType",
		"supplierNotificationEffectiveFromDate",
		"supplierNotificationExpiryDate",
		"meterIdSerialNumber",
		"supplierNominatedAnnualConsumption",
	].includes(key);

const MW2FlowsTable = flowsTable<MW2FlowsResponse>({
	columns: [
		{ label: "ID", key: "id" },
		{ label: "MPAN", key: "mpan" },
		{ label: "Supplier Notification Type", key: "supplierNotificationType" },
		{
			label: "Supplier Notification EFD",
			key: "supplierNotificationEffectiveFromDate",
			formatter: formatJavaStyleDateString,
		},
		{
			label: "Supplier Notification Expiry Date",
			key: "supplierNotificationExpiryDate",
			formatter: formatJavaStyleDateString,
		},
		{ label: "Meter ID Serial Number", key: "meterIdSerialNumber" },
		{
			label: "Supplier Nominated Annual Consumption",
			key: "supplierNominatedAnnualConsumption",
		},
	],
	dataKey: "mw2",
	getId: (response: MW2FlowsResponse) => response.id,
	getColumnContent: (columnKey, response) => {
		// Look up value based on where in the response type the column key exists.
		if (responseKey(columnKey)) return response[columnKey];

		if (responseDataKey(columnKey)) return response.data[columnKey];

		throw Error("Unknown column key");
	},
});

export const MW2Flows = () => (
	<PagedRequestsPage<MW2FlowsResponse>
		title="Consumption Update"
		requestsName={"Consumption Update"}
		requestsKey={"mw2-flows"}
		getRequests={getMW2Flows}
		RequestsTable={MW2FlowsTable}
		additionLink={"/add-consumption-update-request"}
		getRequestsOptions={{}}
	/>
);
