import React, { useContext, useState } from "react";

import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	MenuItem,
	Typography,
} from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import { UseMutationResult } from "react-query";

import {
	ChangeReadResponse,
	CorrectReadType,
	IChangeReadFormValues,
} from "../../models/talos";
import {
	TalosButton,
	TalosDatePicker,
	TalosDropDown,
	TalosNumberBox,
	TalosRadioGroup,
	TalosTextBox,
} from "../forms";
import { formValidationSchema } from "./add-change-read-request-form.validation-schema";
import {
	AlertContext,
	correctAndIncorrectReadingsAreTooCloseTogether,
	defaultAlertState,
	PAST,
	XWorkdaysFromDate,
} from "../../utilities";
import { theme } from "../../styles/theme";
import { ReadingsTable } from ".";
import { TalosMpanField } from "../forms/talos-mpan-field";
import { useRecentSettlementsAlert } from "../../utilities/settlements-reads.helpers";
import {
	getChangeReadRequests,
	SETTLEMENTS_READS_TYPES,
} from "../../api/talos";

interface IProps {
	formSubmitMutation: UseMutationResult<Boolean, Error, IChangeReadFormValues>;
	formData: IChangeReadFormValues;
	onFirstTouch?: () => void;
	submitDisabled?: boolean;
}

interface FormWrapperProps {
	children: React.ReactNode;
	mpan: string;
}

const FormWrapper = ({ children, mpan }: FormWrapperProps) => {
	useRecentSettlementsAlert<ChangeReadResponse>(
		mpan,
		SETTLEMENTS_READS_TYPES.CHANGE_READ,
		getChangeReadRequests
	);
	return <>{children}</>;
};

export const AddChangeReadRequestForm: React.FC<IProps> = ({
	formSubmitMutation,
	formData,
}) => {
	const [showWarning, setShowWarning] = useState(false);

	const { setTalosAlert } = useContext(AlertContext);

	const handleOnSubmit = (
		values: IChangeReadFormValues,
		_form: FormikHelpers<IChangeReadFormValues>,
		shouldSubmit: boolean = false
	) => {
		setTalosAlert(defaultAlertState);

		if (
			correctAndIncorrectReadingsAreTooCloseTogether(values) &&
			!shouldSubmit
		) {
			setShowWarning(true);
		} else {
			setShowWarning(false);
			formSubmitMutation.mutate(values);
		}
	};

	const minimumReadDate = () => XWorkdaysFromDate(new Date(), 292, PAST);

	return (
		<Formik
			enableReinitialize
			onSubmit={handleOnSubmit}
			initialValues={formData}
			validationSchema={formValidationSchema}
			validateOnChange={true}
		>
			{(form) => (
				<FormWrapper mpan={form.values.mpan.toString()}>
					<Form>
						<Box className="form-wrapper">
							<Box className="form-column">
								<TalosMpanField fieldName="mpan" label="MPAN*" form={form} />
								<TalosTextBox
									fieldName="msn"
									label="Meter Serial No.*"
									form={form}
								/>
								<TalosDatePicker
									fieldName="readDate"
									label="Read Date*"
									form={form}
									disableFuture={true}
									minDate={minimumReadDate()}
								/>
								<TalosDropDown
									fieldName="correctReadType"
									label="Read Type*"
									form={form}
									menuItems={Object.keys(CorrectReadType).map((type, idx) => (
										<MenuItem key={`read_type_${idx}`} value={type}>
											{type}
										</MenuItem>
									))}
								/>
								<TalosTextBox
									fieldName="firstRegisterId"
									label="First Register ID*"
									form={form}
								/>
								<TalosNumberBox
									fieldName="firstRegisterIncorrectRead"
									label="First Register Incorrect Read*"
									form={form}
								/>
								<TalosNumberBox
									fieldName="firstRegisterCorrectRead"
									label="First Register Correct Read*"
									form={form}
								/>
								<h4>Does the meter have a second register?</h4>
								<TalosRadioGroup
									form={form}
									fieldName="subRegisters"
									options={[
										{
											value: "Y",
											label: "Yes",
										},
										{
											value: "N",
											label: "No",
										},
									]}
									postOnChange={(evt, value) => {
										if (value === "N") {
											form.setFieldValue("secondRegisterId", undefined);
											form.setFieldValue("secondRegisterIncorrectRead", "");
											form.setFieldValue("secondRegisterCorrectRead", "");
											form.setFieldValue("subRegisters", "N");
										}
									}}
								/>
								{form.values.subRegisters === "Y" && (
									<>
										<TalosTextBox
											fieldName="secondRegisterId"
											label="Second Register ID*"
											form={form}
										/>
										<TalosNumberBox
											fieldName="secondRegisterIncorrectRead"
											label="Second Register Incorrect Read*"
											form={form}
										/>
										<TalosNumberBox
											fieldName="secondRegisterCorrectRead"
											label="Second Register Correct Read*"
											form={form}
										/>
									</>
								)}
								<TalosButton
									fieldName="form_submit"
									form={form}
									buttonText="Submit"
									loading={formSubmitMutation.isLoading}
									onReset={() => form.resetForm()}
									enableIfNotDirty={!!formData.firstRegisterId}
								/>
								<Dialog
									open={showWarning}
									onClose={() => {
										setShowWarning(false);
									}}
									sx={{
										"& .MuiDialog-paper": { width: "80%", maxHeight: 435 },
									}}
								>
									<DialogTitle
										sx={{
											background: theme.palette.warning.light,
										}}
									>
										Are you sure you want to proceed?
									</DialogTitle>
									<DialogContent dividers>
										<Typography variant="body1">
											The difference between the total of the correct reads and
											the total of the incorrect reads should be greater than{" "}
											<strong>250 units.</strong>
										</Typography>
										<ReadingsTable values={form.values} />
									</DialogContent>
									<DialogActions>
										<Button
											variant="outlined"
											onClick={() => setShowWarning(false)}
										>
											Cancel
										</Button>
										<Button
											variant="contained"
											onClick={() => handleOnSubmit(form.values, form, true)}
										>
											Ok
										</Button>
									</DialogActions>
								</Dialog>
							</Box>
						</Box>
					</Form>
				</FormWrapper>
			)}
		</Formik>
	);
};
