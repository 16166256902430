import { DropDownItem } from "./ui-models";

export interface ID0205FlowsFilter {
	mpan: string | undefined;
	start: Date;
	end: Date;
}

export interface D0205FlowsResponse {
	id: number;
	flow_id: number;
	user_id: string;
	mpan: number;
	status: string;
	flow_type: string;
	created_at: string;
	updated_at: string;
}

export interface IAddD0205Flow {
	mpan: string;
	energisationStatus?: string;
	effectiveFromDateMSES?: string;
	measurementClassId?: string;
	effectiveFromDateMSMC?: string;
	meterTimeswitchCode?: string;
	meterTimeswitchCodeEffectiveFromDate?: string;
	pcId?: string;
	effectiveFromDateMSPC?: string;
	sscId?: string;
	effectiveFromDateSCON?: string;
	dataAggregatorId?: string;
	dataAggregationType?: string;
	effectiveFromDateDAA?: string;
	dataCollectorId?: string;
	dataCollectorType?: string;
	effectiveFromDateDCA?: string;
	meterOperatorId?: string;
	meterOperatorType?: string;
	effectiveFromDateMOA?: string;
	ihdStatus?: string;
	effectiveFromDateIHDI?: string;
	smartMeterOperatorId?: string;
	effectiveFromDateSMSO?: string;
	smetsVersion?: string;
}

export const initialAddD0205FlowFormValues: IAddD0205Flow = {
	mpan: "",
	energisationStatus: "",
	effectiveFromDateMSES: "",
	measurementClassId: "",
	effectiveFromDateMSMC: "",
	meterTimeswitchCode: "",
	meterTimeswitchCodeEffectiveFromDate: "",
	pcId: "",
	effectiveFromDateMSPC: "",
	sscId: "",
	effectiveFromDateSCON: "",
	dataAggregatorId: "",
	dataAggregationType: "",
	effectiveFromDateDAA: "",
	dataCollectorId: "",
	dataCollectorType: "",
	effectiveFromDateDCA: "",
	meterOperatorId: "",
	meterOperatorType: "",
	effectiveFromDateMOA: "",
	ihdStatus: "",
	effectiveFromDateIHDI: "",
	smartMeterOperatorId: "",
	effectiveFromDateSMSO: "",
	smetsVersion: "",
};

export interface D0205FlowRequest {
	mpan: string;
	energisation_status: string;
	profile_class_id: string;
	ssc: string;
	meter_type: string;
	update_ssc: boolean;
}

export const ENERGISATION_STATUSES: DropDownItem[] = [
	{ display: "D", value: "D" },
	{ display: "E", value: "E" },
];

export const METER_TYPES: DropDownItem[] = [
	{ display: "N", value: "N" },
	{ display: "K", value: "K" },
	{ display: "S1", value: "S1" },
	{ display: "S2AD", value: "S2AD" },
	{ display: "NSS", value: "NSS" },
	{ display: "H", value: "H" },
	{ display: "RCAMY", value: "RCAMY" },
	{ display: "Meterless", value: "Meterless" },
];
