import dayjs from "dayjs";
import * as Yup from "yup";

import { DualJob, ElecJob, GasJob, RequestType } from "../../models/talos";
import {
	hasLeadingZeros,
	isAdhoc,
	isBankHoliday,
	isDateAtLeastXDaysInTheFuture,
	isDateAtLeastXWorkdaysInTheFuture,
	MAX_REG_ID,
	maxLengthValidationMessage,
	postCodes,
	usefulRegex,
} from "../../utilities";

export const formValidationSchema = Yup.object().shape(
	{
		requestType: Yup.number().required(),
		mpan: Yup.string()
			.matches(usefulRegex.MPAN, "Must be 13 integers")
			.when("requestType", {
				is: (value: RequestType) => value != RequestType.GasOnly,
				then: (schema) =>
					schema
						.test(
							"mpan-no-trailing-0",
							"MPAN must not have leading 0",
							(value) => !!value && !hasLeadingZeros(value)
						)
						.required("MPAN is required for electric meters"),
			}),
		mprn: Yup.string()
			.matches(usefulRegex.MPRN, "Must be betwen 6 and 10 integers")
			.when("requestType", {
				is: (value: RequestType) => value != RequestType.ElectricityOnly,
				then: (schema) =>
					schema
						.test(
							"mprn-no-trailing-0",
							"MPRN must not have leading 0",
							(value) => !!value && !hasLeadingZeros(value)
						)
						.required("MPRN is required for Gas meters"),
			}),
		gas_msn: Yup.string().when("requestType", {
			is: (value: RequestType) => value !== RequestType.ElectricityOnly,
			then: (schema) => schema.required("MSN is required for gas meters"),
		}),
		elec_msn: Yup.string()
			.matches(
				usefulRegex.ELEC_MSN,
				"MSN must be between 3 and 10 alphanumeric characters"
			)
			.when("requestType", {
				is: (value: RequestType) => value !== RequestType.GasOnly,
				then: (schema) =>
					schema
						.test(
							"msn-no-trailing-0",
							"MSN must not have leading 0",
							(value) => !!value && !hasLeadingZeros(value)
						)
						.required("MSN is required for electric meters"),
			}),
		customerName: Yup.string()
			.required("Customer Full Name is required")
			.max(50, maxLengthValidationMessage(50, "Customer Full Name"))
			.matches(
				usefulRegex.AT_LEAST_ONE_LETTER,
				"Must contain at least one letter"
			),
		houseNumber: Yup.string()
			.ensure()
			.when("houseName", {
				is: (value: string) => !value,
				then: (schema) =>
					schema
						.required("House Name or House Number is required")
						.max(50, maxLengthValidationMessage(50, "House Number")),
			}),
		houseName: Yup.string()
			.ensure()
			.when("houseNumber", {
				is: (value: string) => !value,
				then: (schema) =>
					schema
						.required("House Name or House Number is required")
						.max(50, maxLengthValidationMessage(50, "House Name")),
			}),
		address: Yup.string()
			.required("Address is required")
			.max(100, maxLengthValidationMessage(100, "House Name"))
			.matches(
				usefulRegex.AT_LEAST_ONE_LETTER,
				"Must contain at least one letter"
			),
		postOutcode: Yup.string()
			.matches(
				postCodes.POST_OUTCODE_REGEX,
				"Must be valid post outcode e.g. BS1"
			)
			.required("Post Outcode is required"),
		postIncode: Yup.string()
			.matches(
				postCodes.POST_INCODE_REGEX,
				"Must be valid post incode e.g. 6ED"
			)
			.required("Post Incode is required"),
		accessInstructions: Yup.string().max(
			200,
			maxLengthValidationMessage(200, "Access Instructions")
		),
		gas_meterLocation: Yup.string()
			.max(100, maxLengthValidationMessage(100, "Meter Location"))
			.matches(
				usefulRegex.AT_LEAST_ONE_LETTER,
				"Must contain at least one letter"
			),
		elec_meterLocation: Yup.string()
			.max(100, maxLengthValidationMessage(100, "Meter Location"))
			.matches(
				usefulRegex.AT_LEAST_ONE_LETTER,
				"Must contain at least one letter"
			),
		specialInstructions: Yup.string().max(
			200,
			maxLengthValidationMessage(200, "Special Instructions")
		),
		customerCareDetails: Yup.string().max(
			200,
			maxLengthValidationMessage(200, "Customer Care Details")
		),
		customerPassword: Yup.string().max(
			100,
			maxLengthValidationMessage(100, "Customer Password")
		),
		gas_registerID: Yup.string().when("requestType", {
			is: (value: RequestType) => value !== RequestType.ElectricityOnly,
			then: (schema) =>
				schema
					.required("Gas Register ID is required for Gas Meters")
					.max(
						MAX_REG_ID,
						maxLengthValidationMessage(MAX_REG_ID, "First Register ID")
					),
		}),
		gas_registerName: Yup.string(),
		elec_registerID: Yup.string().when("requestType", {
			is: (value: RequestType) => value !== RequestType.GasOnly,
			then: (schema) =>
				schema
					.required("Register ID is required for electric meters")
					.max(
						MAX_REG_ID,
						maxLengthValidationMessage(MAX_REG_ID, "First Register ID")
					),
		}),
		elec_registerName: Yup.string().when("requestType", {
			is: (value: RequestType) => value !== RequestType.GasOnly,
			then: (schema) =>
				schema
					.required("Register Name is required for electric meters")
					.max(100, maxLengthValidationMessage(100, "First Register Name")),
		}),
		elec_secondRegisterID: Yup.string().when("subRegisters", {
			is: true,
			then: (schema) =>
				schema
					.required("Second Register ID is required for Elec Multi Meters")
					.max(
						MAX_REG_ID,
						maxLengthValidationMessage(MAX_REG_ID, "Second Register ID")
					),
		}),
		elec_secondRegisterName: Yup.string().when("subRegisters", {
			is: true,
			then: (schema) =>
				schema
					.required("Second Register Name is required for Elec Multi Meters")
					.max(100, maxLengthValidationMessage(100, "Second Register Name")),
		}),
		jobType: Yup.string().required("Job Type is required"),
		appointmentDate: Yup.string()
			.nullable()
			.when("jobType", {
				is: (value: GasJob | ElecJob | DualJob) => !isAdhoc(value),
				then: (schema) =>
					schema
						.required("Appointment Date is required for non Ad Hoc Jobs")
						.test(
							"ad-min-5-days",
							"Appointment Date must be at least 5 days in the future",
							(value: string) =>
								isDateAtLeastXWorkdaysInTheFuture(new Date(value), 5)
						)
						.test(
							"ad-bank-holiday",
							"Appointment Date cannot be on a bank holiday",
							(value: string) => {
								if (!value) return false;
								const d = new Date(value);
								if (!d) return false;

								return !isBankHoliday(d);
							}
						),
			}),
		appointmentTime: Yup.string().when("jobType", {
			is: (value: GasJob | ElecJob | DualJob) => !isAdhoc(value),
			then: (schema) =>
				schema.required("Appointment Time is required for non Ad Hoc Jobs"),
		}),
		earliestReadDate: Yup.string()
			.nullable()
			.when("jobType", {
				is: (value: GasJob | ElecJob | DualJob) => isAdhoc(value),
				then: (schema) =>
					schema
						.test(
							"erd-min-5-days",
							"Earliest Read Date must be at least 5 days in the future",
							(value: string | null | undefined) => {
								if (!value) return false;
								const d = new Date(value);
								if (!d) return false;

								return isDateAtLeastXDaysInTheFuture(d, 5);
							}
						)
						.test(
							"earliest-read-date-bank-holiday",
							"Earliest Read Date cannot be on a bank holiday",
							(value: string | null | undefined) => {
								if (!value) return false;
								const d = new Date(value);
								if (!d) return false;

								return !isBankHoliday(new Date(value));
							}
						)
						.required("Earliest Read Date is required for Ad Hoc Jobs"),
			}),
		latestReadDate: Yup.string()
			.nullable()
			.when("jobType", {
				is: (value: GasJob | ElecJob | DualJob) => isAdhoc(value),
				then: (schema) =>
					schema.required("Latest Read Date is required for Ad Hoc Jobs"),
			})
			.when("earliestReadDate", {
				is: (value: Date) => !!value,
				then: (schema) =>
					schema
						.test(
							"is-older-than-earliest-date",
							"Latest Read Date must be at least 5 days after the Earliest Read Date",
							(value, context) =>
								dayjs(value) >=
								dayjs(context.parent.earliestReadDate).add(5, "day")
						)
						.test(
							"latest-read-date-bank-holiday",
							"Latest Read Date cannot be on a bank holiday",
							(value) => !!value && !isBankHoliday(new Date(value))
						),
			}),

		subRegisters: Yup.string().required(),
	},
	[["houseName", "houseNumber"]]
);
