import { AxiosResponse } from "axios";
import { Form, Formik } from "formik";
import { Button, MenuItem, Stack } from "@mui/material";

import { AdditionFormProps } from "../../form-generator";
import { IUpdateIHDStatus } from "../../models/metering";
import { IHDStatus } from "../../models/metering/common";
import { updateIHDStatusFormValidationSchema } from "../metering/change-of-registration-data";
import { TalosMpanField } from "../forms/talos-mpan-field";
import { ThemisDateField } from "../forms/themis-date-field";
import { TalosDropDown } from "../forms";

export const UpdateIHDStatusForm = ({
	formSubmitMutation,
	formData,
}: AdditionFormProps<IUpdateIHDStatus, AxiosResponse>) => (
	<Formik
		onSubmit={(values: IUpdateIHDStatus, { resetForm }) => {
			return formSubmitMutation.mutate(values, {
				onSuccess: () => resetForm(),
			});
		}}
		initialValues={formData}
		validationSchema={updateIHDStatusFormValidationSchema}
		validateOnChange
		enableReinitialize
	>
		{(form) => (
			<Form>
				<Stack gap={2}>
					<TalosMpanField label={"MPAN*"} form={form} fieldName={"mpan"} />
					<TalosDropDown
						fieldName="ihdStatus"
						label="IHD Status*"
						form={form}
						menuItems={Object.entries(IHDStatus).map((val, index) => (
							<MenuItem key={`ihd_status_${index}`} value={val[0]}>
								{`${val[1]}${val[0] ? ` - ${val[0]}` : ""}`}
							</MenuItem>
						))}
					/>
					{form.values.ihdStatus && (
						<ThemisDateField
							value={form.values.ihdStatusEffectiveFromDate!!}
							name="ihdStatusEffectiveFromDate"
							label="IHD Status Effective From Date"
							helperText={form.errors.ihdStatusEffectiveFromDate}
							error={!!form.errors.ihdStatusEffectiveFromDate}
							form={form}
						/>
					)}
				</Stack>
				<Stack direction={"row"} gap={2} marginTop={2}>
					<Button
						variant="contained"
						type="submit"
						disabled={!form.isValid || formSubmitMutation.isLoading}
					>
						Submit
					</Button>
				</Stack>
			</Form>
		)}
	</Formik>
);
