import { useCallback, useContext } from "react";

import {
	getRecentSettlementsRequests,
	SETTLEMENTS_READS_TYPES,
} from "../api/talos";
import { AuthContext, IAuthContext } from "../auth";
import { BaseSettlementsReadsResponse } from "../models/talos/base-settlements-reads";
import { Page } from "../components/pagination/pageable";
import { useRecentRequestAlert } from "./alerts";
import { FVRResponse } from "../models/talos/force-validation-reads";
import { ChangeReadResponse } from "../models/talos";

const details: Record<SETTLEMENTS_READS_TYPES, (mostRecent: any) => string> = {
	[SETTLEMENTS_READS_TYPES.FVR]: (mostRecent: FVRResponse) =>
		`for read type ${mostRecent.readType}`,
	[SETTLEMENTS_READS_TYPES.DMEX]: () => "",
	[SETTLEMENTS_READS_TYPES.CHANGE_READ]: (mostRecent: ChangeReadResponse) =>
		`for corrected read type ${mostRecent.correctReadType}`,
};

const buildMessage = (
	readType: SETTLEMENTS_READS_TYPES,
	mpan: string,
	requests: Page<BaseSettlementsReadsResponse>
) => {
	const mostRecent = requests.items[0];
	const createdAt = mostRecent.createdAt;

	const detailFunction = details[readType];
	const detail = detailFunction ? detailFunction(mostRecent) : "";

	return `A request was already made against MPAN ${mpan} on ${createdAt}${
		detail ? ` ${detail}` : "."
	}`;
};
export const useRecentSettlementsAlert = <
	TResponse extends BaseSettlementsReadsResponse
>(
	mpan: string,
	readType: SETTLEMENTS_READS_TYPES,
	getRequests: (
		authContext: IAuthContext,
		getFlowsOptions?: unknown
	) => Promise<Page<TResponse>>,
	days: number = 5
) => {
	const authContext = useContext(AuthContext);

	const getRecentRequest = useCallback(async () => {
		if (mpan.length < 13) return;

		return await getRecentSettlementsRequests(
			authContext,
			mpan,
			getRequests,
			days
		);
	}, [getRequests, authContext, mpan, days]);

	const getAlertMessage = useCallback(
		(results: Page<BaseSettlementsReadsResponse>) =>
			buildMessage(readType, mpan, results),
		[readType, mpan]
	);

	return useRecentRequestAlert(getRecentRequest, getAlertMessage, "warning");
};
