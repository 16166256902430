import React, { useCallback } from "react";

import { addS33FlowRequest } from "../../api/talos";
import {
	IFlowFormValues,
	MeterRegisterDetailPerMeter,
	S33FlowRequest,
} from "../../models/talos/s33-flows";
import { AddS33FlowForm } from "../../components/s33";
import { dateWithoutTimezone } from "../../components/forms/talos-date-field";
import { AdditionPage } from "../addition-page";

const initialValues: IFlowFormValues = {
	mpan: "",
	flowAndDestinationTypes: "",
	meterIdSerialNumber: "",
	readingType: "",
	siteVisitCheckCode: "",
	additionalInformation: "",
	doesHaveASecondRegister: "N",
	meterRegisterIdOne: "",
	timePatternRegimeOne: "",
	readingDateTimeOne: "",
	registerReadingOne: "",
	readingMethodOne: "",
	meterRegisterIdTwo: "",
	timePatternRegimeTwo: "",
	readingDateTimeTwo: "",
	registerReadingTwo: "",
	readingMethodTwo: "",
};

export enum S33Type {
	METER_READING,
	READ_OVERRIDE,
}

const mapFormToValues =
	(type: S33Type) =>
	(values: IFlowFormValues): S33FlowRequest => {
		const meterRegisterDetails: MeterRegisterDetailPerMeter[] = [];
		meterRegisterDetails.push({
			meterRegisterId: values.meterRegisterIdOne,
			timePatternRegime:
				values.timePatternRegimeOne === "" ? null : values.timePatternRegimeOne,
			readingDateTime: dateWithoutTimezone(new Date(values.readingDateTimeOne)),
			registerReading: values.registerReadingOne,
			readingMethod:
				values.readingMethodOne === "" ? null : values.readingMethodOne,
			meterReadingFlag: null,
			readingOverrideFlag: type === S33Type.READ_OVERRIDE,
		});
		if (values.doesHaveASecondRegister === "Y") {
			meterRegisterDetails.push({
				meterRegisterId: values.meterRegisterIdTwo,
				timePatternRegime:
					values.timePatternRegimeTwo === ""
						? null
						: values.timePatternRegimeTwo,
				readingDateTime: dateWithoutTimezone(
					new Date(values.readingDateTimeTwo)
				),
				registerReading: values.registerReadingTwo,
				readingMethod:
					values.readingMethodTwo === "" ? null : values.readingMethodTwo,
				meterReadingFlag: null,
				readingOverrideFlag: type === S33Type.READ_OVERRIDE,
			});
		}
		return {
			mpan: values.mpan,
			flowAndDestinationTypes: values.flowAndDestinationTypes,
			meterIdSerialNumber: values.meterIdSerialNumber,
			readingType: values.readingType,
			siteVisitCheckCode: values.siteVisitCheckCode,
			additionalInformation:
				values.additionalInformation === ""
					? null
					: values.additionalInformation,
			meterRegisterDetailsPerMeter: meterRegisterDetails,
		};
	};

const getS33TypeTitle = (type: S33Type): string => {
	switch (type) {
		case S33Type.METER_READING: {
			return "Meter reading to industry";
		}
		case S33Type.READ_OVERRIDE: {
			return "Read override";
		}
		default:
			return "S3-S33 Flow";
	}
};

export const AddS33Flow = ({ type }: { type: S33Type }) => {
	const pageTitle = getS33TypeTitle(type);
	/* eslint react-hooks/exhaustive-deps: off --- exhaustive deps prefers
	 * inline functions, but it's nicer to have it assigned to a constant. */
	const mappingFunction = useCallback(mapFormToValues(type), [
		mapFormToValues,
		type,
	]);

	return (
		<AdditionPage<S33FlowRequest, IFlowFormValues>
			additionFormKey="add-s33-flow"
			additionInstanceName={pageTitle}
			// header={`New ${pageTitle}`}
			// summary={`Fill in this form to request a new ${pageTitle}.`}
			underlyingFlowName="S0003_S33"
			mapFormToValues={mappingFunction}
			addInstanceRequest={addS33FlowRequest}
			AddInstanceForm={AddS33FlowForm}
			initialValues={initialValues}
			listPage={{
				link: "/meter-readings-to-industry-and-read-overrides-requests",
				subject: "S33 flows",
				title: "S33 Flows",
			}}
			successMessage={`${pageTitle} has been sent successfully`}
		/>
	);
};
