import { Box, MenuItem } from "@mui/material";
import { Form, Formik } from "formik";
import { AxiosResponse } from "axios";

import { IAddD0205Flow } from "../../models/talos/d0205-flows";
import { AdditionFormProps } from "../../form-generator";
import {
	EnergisationStatus,
	IHDStatus,
	MeasurementClassId,
	ReadingType,
	SMETSVersion,
} from "../../models/metering/common";
import { PCId } from "../../models/metering";
import {
	TalosTextBox,
	TalosDropDown,
	TalosButton,
	TalosDateField,
} from "../forms";
import { addD0205FlowFormValidationSchema } from "./add-d0205-flow-form.validation-schema";
import { TalosMpanField } from "../forms/talos-mpan-field";

export function mapFormToValues(formValues: IAddD0205Flow): IAddD0205Flow {
	const conditionalFields: Record<string, string> = {
		effectiveFromDateMSES: "energisationStatus",
		effectiveFromDateMSMC: "measurementClassId",
		meterTimeswitchCodeEffectiveFromDate: "meterTimeswitchCode",
		effectiveFromDateMSPC: "pcId",
		effectiveFromDateSCON: "sscId",
		dataAggregationType: "dataAggregatorId",
		effectiveFromDateDAA: "dataAggregatorId",
		dataCollectorType: "dataCollectorId",
		effectiveFromDateDCA: "dataCollectorId",
		meterOperatorType: "meterOperatorId",
		effectiveFromDateMOA: "meterOperatorId",
		effectiveFromDateIHDI: "ihdStatus",
		effectiveFromDateSMSO: "smartMeterOperatorId",
	};

	return Object.entries(formValues).reduce((acc, [key, value]) => {
		if (
			conditionalFields[key] &&
			!(formValues as any)[conditionalFields[key]]
		) {
			(acc as any)[key] = undefined;
		} else {
			(acc as any)[key] = value === "" ? undefined : value;
		}
		return acc;
	}, {} as IAddD0205Flow);
}

export const AddD0205FlowForm = ({
	formSubmitMutation,
	formData,
}: AdditionFormProps<IAddD0205Flow, AxiosResponse>) => {
	return (
		<Formik
			enableReinitialize
			onSubmit={(values: IAddD0205Flow, { resetForm }) =>
				formSubmitMutation.mutate(values, {
					onSuccess: () => resetForm(),
				})
			}
			initialValues={formData}
			validationSchema={addD0205FlowFormValidationSchema}
			validateOnChange
		>
			{(form) => {
				return (
					<Form>
						<Box className="form-wrapper">
							<Box className="form-column">
								<TalosMpanField fieldName="mpan" label="MPAN*" form={form} />
								<TalosDropDown
									fieldName="energisationStatus"
									label="Energisation Status"
									form={form}
									menuItems={[
										["Select", ""],
										...Object.entries(EnergisationStatus),
									].map((val, index) => (
										<MenuItem
											key={`energisation_status_${index}`}
											value={val[1]}
										>
											{`${val[0]}${val[1] ? ` - ${val[1]}` : ""}`}
										</MenuItem>
									))}
								/>
								{form.values.energisationStatus && (
									<TalosDateField
										fieldName="effectiveFromDateMSES"
										label="Energ. Status Effective From Date"
										form={form}
									/>
								)}
								<TalosDropDown
									fieldName="measurementClassId"
									label="Measurement Class Id"
									form={form}
									menuItems={[
										["Select", ""],
										...Object.entries(MeasurementClassId),
									].map((val, index) => (
										<MenuItem
											key={`measurement_class_id${index}`}
											value={val[1]}
										>
											{`${val[0]}${val[1] ? ` - ${val[1]}` : ""}`}
										</MenuItem>
									))}
								/>
								{form.values.measurementClassId && (
									<TalosDateField
										fieldName="effectiveFromDateMSMC"
										label="Measurement Class Effective From Date"
										form={form}
									/>
								)}
								<TalosTextBox
									fieldName="meterTimeswitchCode"
									label="Meter Timeswitch Code"
									form={form}
								/>
								{form.values.meterTimeswitchCode && (
									<TalosDateField
										fieldName="meterTimeswitchCodeEffectiveFromDate"
										label="Meter Timeswitch Code Effective Date"
										form={form}
									/>
								)}

								<TalosDropDown
									fieldName="pcId"
									label="Profile Class Id"
									form={form}
									menuItems={[["", "Select"], ...Object.entries(PCId)].map(
										(val, index) => (
											<MenuItem key={`pc_id_${index}`} value={val[0]}>
												{`${val[1]}${val[0] ? ` - ${val[0]}` : ""}`}
											</MenuItem>
										)
									)}
								/>
								{form.values.pcId && (
									<TalosDateField
										fieldName="effectiveFromDateMSPC"
										label="Profile Class Effective From Date"
										form={form}
									/>
								)}
								<TalosTextBox
									fieldName="sscId"
									label="Standard Settlement Configuration Id"
									form={form}
								/>
								{form.values.sscId && (
									<TalosDateField
										fieldName="effectiveFromDateSCON"
										label="Standard Settlement Configuration Effective From Date"
										form={form}
									/>
								)}
								<h3>Agent Details:</h3>
								<TalosTextBox
									fieldName="dataAggregatorId"
									label="Data Aggregator Id"
									form={form}
								/>
								{form.values.dataAggregatorId && (
									<>
										<TalosDropDown
											fieldName="dataAggregationType"
											label="Data Aggregation Type"
											form={form}
											menuItems={[
												["Select", ""],
												...Object.entries(ReadingType),
											].map((val, index) => (
												<MenuItem
													key={`data_aggregation_type${index}`}
													value={val[1]}
												>
													{`${val[0]}${val[1] ? ` - ${val[1]}` : ""}`}
												</MenuItem>
											))}
										/>
										<TalosDateField
											fieldName="effectiveFromDateDAA"
											label="Data Aggregator Effective From Date"
											form={form}
										/>
									</>
								)}
								<TalosTextBox
									fieldName="dataCollectorId"
									label="Data Collector Id"
									form={form}
								/>
								{form.values.dataCollectorId && (
									<>
										<TalosDropDown
											fieldName="dataCollectorType"
											label="Data Collector Type"
											form={form}
											menuItems={[
												["Select", ""],
												...Object.entries(ReadingType),
											].map((val, index) => (
												<MenuItem
													key={`data_collector_type_${index}`}
													value={val[1]}
												>
													{`${val[0]}${val[1] ? ` - ${val[1]}` : ""}`}
												</MenuItem>
											))}
										/>
										<TalosDateField
											fieldName="effectiveFromDateDCA"
											label="Data Collector Effective From Date"
											form={form}
										/>
									</>
								)}
								<TalosTextBox
									fieldName="meterOperatorId"
									label="Meter Operator Id"
									form={form}
								/>
								{form.values.meterOperatorId && (
									<>
										<TalosDropDown
											fieldName="meterOperatorType"
											label="Meter Operator Type"
											form={form}
											menuItems={[
												["Select", ""],
												...Object.entries(ReadingType),
											].map((val, index) => (
												<MenuItem
													key={`meter_operator_type_${index}`}
													value={val[1]}
												>
													{`${val[0]}${val[1] ? ` - ${val[1]}` : ""}`}
												</MenuItem>
											))}
										/>
										<TalosDateField
											fieldName="effectiveFromDateMOA"
											label="MOP Effective From Date"
											form={form}
										/>
									</>
								)}
								<TalosDropDown
									fieldName="ihdStatus"
									label="IHD Status"
									form={form}
									menuItems={[["", "Select"], ...Object.entries(IHDStatus)].map(
										(val, index) => (
											<MenuItem key={`ihd_status_${index}`} value={val[0]}>
												{`${val[1]}${val[0] ? ` - ${val[0]}` : ""}`}
											</MenuItem>
										)
									)}
								/>
								{form.values.ihdStatus && (
									<TalosDateField
										fieldName="effectiveFromDateIHDI"
										label="IHD Status Effective From Date"
										form={form}
									/>
								)}
								<TalosTextBox
									fieldName="smartMeterOperatorId"
									label="Smart Meter Operator Id"
									form={form}
								/>
								{form.values.smartMeterOperatorId && (
									<TalosDateField
										fieldName="effectiveFromDateSMSO"
										label="Smart Meter Operator Effective From Date"
										form={form}
									/>
								)}
								<TalosDropDown
									fieldName="smetsVersion"
									label="SMETS Version"
									form={form}
									menuItems={[
										["Select", ""],
										...Object.entries(SMETSVersion),
									].map((val, index) => (
										<MenuItem key={`smets_version${index}`} value={val[1]}>
											{`${val[0]}${val[1] ? ` - ${val[1]}` : ""}`}
										</MenuItem>
									))}
								/>
								<TalosButton
									fieldName="form_submit"
									form={form}
									buttonText="Submit"
									loading={formSubmitMutation.isLoading}
								/>
							</Box>
						</Box>
					</Form>
				);
			}}
		</Formik>
	);
};
