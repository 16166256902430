import { useContext, useEffect, useState } from "react";

import { isAxiosError } from "axios";

import { AuthContext } from "../auth";
import { fetchAllowedSscIds } from "../api/metering";
import { AlertContext } from "../utilities";

export const useSscIdFilter = (mpan?: number, pcId?: string) => {
	const authContext = useContext(AuthContext);
	const { setTalosAlert } = useContext(AlertContext);

	const [sscIds, setSscIds] = useState<Record<string, string>>({});

	useEffect(() => {
		let isFetching = false;

		const fetchSscIds = async () => {
			if (mpan && mpan.toString().length === 13) {
				try {
					const response = await fetchAllowedSscIds(authContext, mpan, pcId);

					if (!isFetching) {
						setSscIds(response.data);
					}
				} catch (e) {
					if (isAxiosError(e) && typeof e.response?.data === "string")
						setTalosAlert({
							message: e.response.data,
							severity: "error",
						});
				}
			}
		};

		fetchSscIds();

		return () => {
			isFetching = true;
		};
	}, [mpan, pcId, authContext, setTalosAlert]);

	return sscIds;
};
